import {CircularProgress} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import React, {useEffect, useState} from "react";
import { copy } from "@stianlarsen/copy-to-clipboard";
import toast from "react-hot-toast";
import {supabase} from "../utils/supabase";
import { ReactTabulator } from 'react-tabulator'
import {useDbContext} from "../contexts/dbContext";
import usePagination from "@mui/material/usePagination";
import {cardlist} from "../utils/cardlist";

const rcardoverrides = [
    {
        "_id": "64bc01296557f9fcad067b1a",
        "Name": "Glimmerbough Shard"
    },
    {
        "_id": "64bc01296557f9fcad067b19",
        "Name": "Overgrown Quarry"
    },
    {
        "_id": "64bc01296557f9fcad067b16",
        "Name": "Lavacore Gem"
    },
    {
        "_id": "64bc01296557f9fcad067b27",
        "Name": "Emberleaf Tangle"
    },
    {
        "_id": "64bc01296557f9fcad067b2a",
        "Name": "Shocksteel Quarry"
    },
    {
        "_id": "64bc01296557f9fcad067b21",
        "Name": "Steamblaze Shore"
    },
    {
        "_id": "64bc01296557f9fcad067b28",
        "Name": "Moonglow Geode"
    },
    {
        "_id": "64bc01296557f9fcad067b25",
        "Name": "Darkwood Tangle"
    },
    {
        "_id": "64bc01296557f9fcad067b22",
        "Name": "Nightfire Badlands"
    },
    {
        "_id": "64bc01296557f9fcad067b18",
        "Name": "Shadowsteel Quarry"
    },
    {
        "_id": "64bc01296557f9fcad067b20",
        "Name": "Shadowmist Estuary"
    },
    {
        "_id": "64bc01296557f9fcad067b24",
        "Name": "Enduring Ingot"
    },
    {
        "_id": "64bc01296557f9fcad067b23",
        "Name": "Sunstone Crystal"
    },
    {
        "_id": "64bc01296557f9fcad067b1d",
        "Name": "Sungrace Expanse"
    },
    {
        "_id": "64bc01296557f9fcad067b1f",
        "Name": "Scorching Mesa"
    },
    {
        "_id": "64bc01296557f9fcad067b1e",
        "Name": "Penumbral Prairie"
    },
    {
        "_id": "64bc01296557f9fcad067b17",
        "Name": "Sunsteel Quarry"
    },
    {
        "_id": "64bc01296557f9fcad067b1c",
        "Name": "Sunspray Strand"
    },
    {
        "_id": "64bc01296557f9fcad067b29",
        "Name": "Eternal Ice"
    },
    {
        "_id": "64bc01296557f9fcad067b1b",
        "Name": "Mistwood Coast"
    },
    {
        "_id": "64bc01296557f9fcad067b26",
        "Name": "Anchorsteel Quarry"
    }
]

export default function CardUpdate(props) {
    const [updatingCards, setUpdatingCards] = useState(false);
    const [key, setKey] = useState(0);
    const [dataSet, setDataSet] = useState(2);
    const {getcards, units, actions, identities} = useDbContext();
    const [cards, setCards] = useState([]);

    const jsonMutator = function(value, data, type, params, component){
        return JSON.stringify(value); //return the new value for the cell data.
    }

    const columns = [
        {title: "Type", field: "type"},
        {title: "ID", field: "_id"},
        {title: "CardName", field: "name", editor:"input"},
        {title: "Identity", field: "identity", editor:"list", editorParams:{ placeholderLoading:"Loading List...", emptyValue:null,listOnEmpty:true,verticalNavigation:"hybrid", sort:"asc", //sort direction for the values list
                defaultValue:"Neutral",  autocomplete:true,
                values: [
                    "Earth",
                    "Flame",
                    "Lunar",
                    "Metal",
                    "Solar",
                    "Water",
                    "Neutral",
                    "Flame-Metal",
                    "Flame-Water",
                    "Lunar-Earth",
                    "Lunar-Flame",
                    "Solar-Earth",
                    "Solar-Lunar",
                    "Solar-Metal",
                    "Water-Earth",
                    "Water-Metal"
                ]}},
        {title: "Text", field: "ability", hozAlign: "center", editor:"input"},
        {title: "Keywords", field: "keywords", hozAlign: "center", editor:"input", width:300},
        {title: "Costs", field: "costs", hozAlign: "center",mutator: jsonMutator, editor:"textarea", width:300},
        {title: "Triggers", field: "triggers", hozAlign: "center",mutator: jsonMutator, editor:"textarea", width:300}
    ];

    async function UpdateCards(cindex) {
        setUpdatingCards(true);
        let units = [];
        let actions = [];
        let resources = [];
        await fetch("https://card-calc-db.onrender.com/export-json").then(response => response.json().then(data => {
            if(data){
                for (let c = 0; c < data.length; c++) {
                    let card = data[c];
                    let triggers = card.triggers;
                    if (triggers.length > 0) {
                        for (let i = 0; i < triggers.length; i++) {
                            let trigg = triggers[i];
                            if (typeof trigg.name !== "string") trigg.name = trigg.name.toString();
                            if (typeof trigg.condition !== "string") trigg.condition = trigg.condition.toString();
                            if (typeof trigg.targetType !== "string") trigg.targetType = trigg.targetType.toString();
                            if (typeof trigg.targetQty !== "number") trigg.targetQty = isNaN(parseInt(trigg.targetQty)) ? 0 : parseInt(trigg.targetQty);
                            if (typeof trigg.execute !== "string") trigg.execute = trigg.execute.toString();
                            if (typeof trigg.orFlag !== "boolean") trigg.orFlag =trigg.orFlag === "true";
                            if (!Array.isArray(trigg.effect)) trigg.effect = [];
                            else {
                                for (let i2 = 0; i2 < trigg.effect.length; i2++) {
                                    let eff = trigg.effect[i2];
                                    if (typeof eff.valueA !== "number") eff.valueA = isNaN(parseInt(eff.valueA)) ? 0 : parseInt(eff.valueA);
                                    if (typeof eff.valueB !== "number") eff.valueB = isNaN(parseInt(eff.valueB)) ? 0 : parseInt(eff.valueB);
                                    if (typeof eff.duration !== "string") eff.duration = eff.duration.toString();
                                    if (typeof eff.flag !== "string") eff.flag = eff.flag.toString();
                                    if (!Array.isArray(eff.valueC)) {
                                        let vc = [];
                                        vc.push(eff.valueC.toString());
                                        eff.valueC = vc;
                                    } else {
                                        for (let vals = 0; vals < eff.valueC.length; vals++) {
                                            let val = eff.valueC[vals];
                                            if (typeof val !== "string") eff.valueC[vals] = val.toString();
                                        }
                                    }
                                    trigg.effect[i2] = eff;
                                }
                            }
                            card.triggers[i] = trigg;
                        }
                    }

                    if (card.type === "Action") {
                        const acard = {
                            _id: card.id,
                            name: card.name,
                            abilitydescription: card.ability,
                            ability: card.ability,
                            identity: card.identity,
                            costs: card.costs,
                            triggers: card.triggers,
                            keywords: card.keywords,
                            edition: card.edition,
                            inFirstEdition: card.inFirstEdition,
                            specialEdition: card.specialEdition ? card.specialEdition : "",
                            skillCategoryName: card.skillCategoryName ? card.skillCategoryName : "",
                            versions: [{
                                Name: "default",
                                version: 0
                            }]
                        }
                        actions.push(acard);
                    } else if (card.type === "Unit") {
                        const ucard = {
                            _id: card.id,
                            name: card.name,
                            abilitydescription: card.ability,
                            ability: card.ability,
                            identity: card.identity,
                            costs: card.costs,
                            triggers: card.triggers,
                            atk: card.atk,
                            edition: card.edition,
                            def: card.def,
                            keywords: card.keywords,
                            inFirstEdition: card.inFirstEdition,
                            specialEdition: card.specialEdition ? card.specialEdition : "",
                            skillCategoryName: card.skillCategoryName ? card.skillCategoryName : "",
                            versions: [{
                                "Name": "default",
                                "version": 0
                            }]
                        }
                        units.push(ucard);
                    } else if(card.type === "Resource"){
                        const rcard = {
                            _id: card.id,
                            Name: card.name,
                            identity: card.identity,
                            versions: [
                                {
                                    Name: "default",
                                    version: 0
                                }
                            ]
                        }
                        for(let i = 0; i < rcardoverrides.length; i++){
                            if(rcardoverrides[i].Name === card.name) {
                                rcard._id = rcardoverrides[i]._id;
                                break;
                            }
                        }
                        resources.push(rcard);
                    }
                }
                let cdata = { units: units, actions: actions, resources: resources };
                supabase.from('carddata')
                    .update(cdata)
                    .eq('id', cindex)
                    .select().then(result => {
                    if(result.error){
                        toast.error("Failed to update cards: " + result.error, {
                            duration: 4000,
                            position: 'bottom-right',
                        });
                        setUpdatingCards(false);
                    } else {
                        console.log(JSON.stringify(units) + "\n" + JSON.stringify(actions) + "\n" + JSON.stringify(resources));
                        toast.success("updated " + units.length + " unit cards, " + actions.length + " action cards and " + resources.length + " mana cards, total " + (units.length + actions.length), {
                            duration: 4000,
                            position: 'bottom-right',
                        });
                        setUpdatingCards(false);
                    }
                })
            } else {
                toast.error("Failed to update cards.", {
                    duration: 4000,
                    position: 'bottom-right',
                });
                setUpdatingCards(false);
            }
        }));
    }

    const ClipbackCB = () => {
        toast.success("Password has been copied to the clipboard", {
            duration: 4000,
            position: 'bottom-right',
        });
    };

    useEffect(() => {
        let crds = [];
        if(units && units.length > 0) {
            for (let i = 0; i < units.length; i++) {
                units[i].type = "Unit";
                crds.push(units[i]);
            }
        }
        if(actions && actions.length > 0) {
            for (let i = 0; i < actions.length; i++) {
                actions[i].type = "Action";
                crds.push(actions[i]);
            }
        }
        setCards(crds);
    }, [actions, units])

    useEffect(() => {

        if(key === 10){
            setKey(0);
        } else setKey(key + 1);
    }, [updatingCards]);

    const options = {
        height: "100%",
        movableRows: false,
        movableColumns: true,
        pagination: true,
        layout: "fitData",
        contentEditable: true,
        width: "100%",
    };

    return (
        <div className={"flex flex-col w-full h-full overflow-hidden"}>
            <div className={"flex flex-col h-full w-full overflow-hidden"}>
                {!updatingCards && cards &&
                    <ReactTabulator
                    data={cards}
                    options={options}
                    columns={columns}/>
                }
                {updatingCards && <div className={"flex flex-col justify-center items-center h-full w-full"}>
                    <CircularProgress/>
                    <p> Updating Cards..</p>
                </div>}
            </div>
            <div
                className={"flex flex-row w-full h-14 border-t border-[#51b78c] justify-between items-center px-10 gap-2"}>
                <div>
                    <div className={"text-sm"}>username: cards</div>
                    <div className={"text-green-400 hover:opacity-70"} onClick={() => {
                        copy("N anoclub420", ClipbackCB);
                    }}>pass
                    </div>
                </div>


                {updatingCards ?
                    <div
                        className={"bg-[#3b6f5e26] opacity-80 w-auto px-2 flex flex-row h-10 border border-[#51b78c] rounded-xl items-center justify-center"}
                    >
                        <p> Updating Cards..</p>
                    </div>
                    :
                    <div className={"flex flex-row w-auto h-full justify-center items-center p-2 gap-2"}>
                        <div className={"flex flex-row w-auto h-full justify-center items-center gap-2"}>
                            Dataset:
                            <input value={dataSet} onChange={(e) => { setDataSet(parseInt(e.currentTarget.value))}} datatype={"number"} className={"flex flex-row w-[50px] h-full text-center bg-[#244939] border border-[#51b78c] p-1"} />
                        </div>
                        <Tooltip title="Update Cards" placement="right">
                            <div
                                className={"bg-[#3b6f5e26] w-auto px-2 flex flex-row h-10 border border-[#51b78c] rounded-xl hover:opacity-75 items-center justify-center"}
                                onClick={() => {
                                    UpdateCards(dataSet);
                                }}
                            >
                                <RefreshIcon size={"small"} />
                                <p> Update Cards</p>
                            </div>
                        </Tooltip>
                    </div>
                }
            </div>
        </div>
    )
}

function CardsHeader(props) {
    return (
        <thead>
        <tr>
            <th>Type</th>
            <th>ID</th>
            <th>CardName</th>
            <th>Identity</th>
            <th>Costs</th>
            <th>Text</th>
            <th>Triggers</th>
            <th>Keywords</th>
        </tr>
        </thead>
    )
}

const playerentrystyle = "flex-row text-center justify-center items-center border-r border-[#51b78c] border-opacity-40";

function CardItem(props) {
    const {entry, type} = props;

    return (
        <tr className={"border border-[#51b78c] border-opacity-40"}>
            <td className={playerentrystyle}>{type}</td>
            <td className={playerentrystyle}>{entry._id}</td>
            <th className={playerentrystyle}>{entry.name}</th>
            <th className={playerentrystyle}>{entry.identity}</th>
            <td className={playerentrystyle}>{JSON.stringify(entry.costs)}</td>
            <td className={playerentrystyle}>{entry.ability}</td>
            <td className={playerentrystyle}>{JSON.stringify(entry.triggers)}</td>
            <td className={playerentrystyle}>{JSON.stringify(entry.keywords)}</td>
            <td className={playerentrystyle}>
            </td>
        </tr>
    )
}