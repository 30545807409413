import {useStatsContext} from "../contexts/statisticsContext";
import {bordercolor} from "../theme";
import React, {useState} from "react";
import ActiveLogEntry from "./activeLogEntry";
import MatchLogEntry from "./matchlogentry";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MatchLogList from "./matchlogList";

export default function MatchReports(props){
    const {reports, activeReport, setActiveReport, loadingRM, DeleteReport, SortedReports, activeSort, setActiveSort, activeType, setActiveType} = useStatsContext();
    const [hoverId, setHoverID] = useState("");

    return (
        <div className={"flex flex-row h-full w-full gap-3 overflow-hidden p-4 border-[#51b78c] border-t"}>
            <MatchLogList admin={false} activeType={activeType} setActiveType={setActiveType} activeReport={activeReport} activeSort={activeSort} setActiveSort={setActiveSort} setActiveReport={setActiveReport} loadingRM={loadingRM} reports={reports} SortedReports={SortedReports} />
            <div className="flex flex-col h-full w-[80%] overflow-hidden container mx-auto px-2 pt-8">
                    {loadingRM ?
                        <div className="w-full lg:w-4/5 mx-auto">
                            <div className="flex flex-col gap-4 w-52">
                                <div className="skeleton h-32 w-full"></div>
                                <div className="skeleton h-4 w-28"></div>
                                <div className="skeleton h-4 w-full"></div>
                                <div className="skeleton h-4 w-full"></div>
                            </div>
                            <p>Loading Match Reports..</p>
                        </div> :
                        <div className="flex flex-col w-full h-full mx-auto overflow-hidden">
                            {!activeReport &&
                                <div className={"flex flex-col w-full h-full overflow-y-auto"}>
                                    {reports && reports.map((el, ind) =>
                                        <MatchLogEntry user={true} DeleteReport={DeleteReport} activeReport={activeReport} key={ind} ind={ind} report={el}/>)}
                                </div>}
                            {activeReport && <ActiveLogEntry user={true} DeleteReport={DeleteReport} activeReport={activeReport} setHoverID={setHoverID} hoverId={hoverId}/>}
                        </div>}
                </div>
            {hoverId !== "" &&
                <div
                    className={"absolute pointer-events-none flex flex-col min-h-40 h-auto w-[400px] left-4 top-[20%] p-3 overflow-hidden self-center justify-self-center bg-[#0a0f13] rounded-2xl border " + bordercolor}>
                    <img className={"h-auto w-full rounded-2xl"} alt={"preview" + hoverId}
                         src={"/cards/" + hoverId + ".png"}/>
                </div>}
        </div>
    )
}