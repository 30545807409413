import {ActionIcon, Affix, Blockquote, Drawer, List, Transition} from "@mantine/core";
import FeedIcon from "@mui/icons-material/Feed";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import {useDisclosure} from "@mantine/hooks";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

export const dbupdates = [
    {
        title: "Update v0.2.5",
        date: "15/11/2024 20:30",
        updates: [
            "Mana cards per deck increased to 13."
        ]
    },
    {
        title: "Update v0.2.4a",
        date: "13/11/2024 17:30",
        updates: [
            "Filter button moved.",
            "And/Or filter toggles combined as they are opposites.",
            "Not option added to identity and keyword filter to exclude selected."
        ]
    },
    {
        title: "Update v0.2.4",
        date: "12/11/2024 16:00",
        updates: [
            "Improved buttons/toggles.",
            "Improvements for display when deck builders is loaded in game."
        ]
    },
    {
        title: "Update v0.2.3",
        date: "10/11/2024 19:20",
        updates: [
            "DeckBuilder updates feed added.",
            "Auth fixes.", "Password reset fixes.",
            "Optimization fixes for card tooltips.",
            "Borders added to previews (currently a bug, the color will change to the card identity."
        ]
    }
]

const dbversion = "0.2.5"

function UpdateEntry(props){
    const {entry} = props;
    return (
        <Blockquote color="blue" cite={"Released at " + entry.date} icon={<FeedIcon />} mt="xl">
            <p className={"flex h-10 flex-row text-xl bold mb-2 w-full items-start justify-end"}>{entry.title}</p>
            <List spacing="xs"
                  size="sm"
                  center
                  icon={
                      <HorizontalRuleIcon  />
                  }>
                {entry.updates && entry.updates.map((el, ind) => (
                    <List.Item key={"entr" + ind}>{el}</List.Item>
                ))}
            </List>
        </Blockquote>
    )
}

export default function UpdatesFeed(){
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <>
            <Drawer position={"left"}  offset={8} radius="md" opened={opened} onClose={close} title="DeckBuilder Updates Feed">
                {dbupdates.map((entry,ind) => (
                    <UpdateEntry entry={entry} key={ind} />
                ))}

                <Affix position={{ bottom: 10, right: 10 }}>
                    <Transition transition="slide-up" mounted={true}>
                        {(transitionStyles) => (
                            <div className={"bg-gray-900 text-lg text-white p-4 rounded-lg"}>{"Manacaster DeckBuilder v" + dbversion}</div>
                        )}
                    </Transition>
                </Affix>
            </Drawer>
            <Tooltip title={"Open DeckBuilder Updates"} placement="top">
                <ActionIcon size={35} variant="default" aria-label="Sort menu" onClick={opened ? close : open}>
                    <FeedIcon />
                </ActionIcon>
            </Tooltip>
        </>
    );
}