import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import HomeIcon from "@mui/icons-material/Home";
import Tooltip from "@mui/material/Tooltip";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ForumIcon from '@mui/icons-material/Forum';
import FriendList from "./friendlist";
import SocialDash from "./socialdash";
import ChannelView from "./channels";
import NotificationsIcon from '@mui/icons-material/Notifications';
import Requests from "./requests";
import {Badge} from "@mui/material";
import {useSocialContext} from "../contexts/socialContext";
import {SegmentedControl, Tabs} from "@mantine/core";
import AccountManagement from "./accountmanagement";

export function SocialPanel(props) {
    const {panelOpen, setPanelOpen} = props;
    const {RequestsCounter, ChannelCounter} = useSocialContext();
    const [sbOpen, setSbOpen] = useState(false);
    const [openTab, setOpenTab] = useState("dashboard");

 const col = "#3a3a3a";
            return(
            <div style={{display: panelOpen ? "flex" : "none", height: "80%", width: (openTab === "account" || openTab === "dashboard" || openTab === "requests") ? "400px" : "33%", top: 75, right: 0}}
                 className={"fixed flex-col w-full bg-[#222322FF] border-l border-b border-[#717171FF] border-opacity-60 overflow-hidden z-20"}>
                <div
                    className={"flex flex-row h-14 w-full px-2 bg-black bg-opacity-40 border-b border-[#717171FF] border-opacity-40 items-center py-2 justify-between"}>
                    <div className={"flex flex-row h-10 w-10 items-center py-2 justify-center"}>
                        <Tooltip title="Sidebar" placement="right">
                            <div style={{display: openTab === "account" || openTab === "dashboard" || openTab === "requests" ? "none" : "flex"}}
                                 className={(sbOpen ? "bg-[#51b78c] " : "bg-[#3b6f5e26] ") + " flex-row h-10 w-10 cursor-pointer border border-[#717171FF] rounded-xl hover:opacity-75 items-center justify-center"}
                                 onClick={() => {
                                     setSbOpen(!sbOpen)
                                 }}
                            >
                                {sbOpen ? <KeyboardDoubleArrowLeftIcon/> : <KeyboardDoubleArrowRightIcon/>}
                            </div>
                        </Tooltip>
                    </div>
                    <div className={"flex flex-row h-auto w-auto text-lg"}></div>
                    <div className={"flex flex-row h-10 w-auto gap-3 items-center py-2 px-2 justify-center"}>
                        <SegmentedControl
                            value={openTab}
                            onChange={setOpenTab}
                            data={[
                                { label: 'Dashboard', value: 'dashboard' },
                                { label: 'Account', value: 'account' },
                                { label: 'Friends', value: 'friends' },
                                { label: 'Chat', value: 'channels' },
                                { label: 'Requests', value: 'requests' },
                            ]}
                        />
                    </div>
                </div>
                <div className={"flex flex-row h-full w-full overflow-hidden"}>
                    {openTab === "dashboard" && <SocialDash/>}
                    {openTab === "account" && <AccountManagement />}
                    {openTab === "requests" && <Requests/>}
                    {openTab === "friends" && <FriendList sbOpen={sbOpen} setSbOpen={setSbOpen}/>}
                    {openTab === "channels" && <ChannelView sbOpen={sbOpen} setSbOpen={setSbOpen}/>}
                </div>
            </div>
            )
            }