import {supabase} from "../utils/supabase";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import {useSocialContext} from "./socialContext";
import Axios from "axios";
import {dblink} from "../App";

const AdminContext = createContext({});

const AdminContextProvider = ({ children }) => {
    const {users} = useSocialContext();
    const [reports, setReports] = useState([]);
    const [loadingRM, setLoadingMR] = useState(false);
    const [loadingDecks, setLoadingDecks] = useState(false);
    const [activeReport, setActiveReport] = useState(null);
    const [newreports, setNewReports] = useState([]);
    const [activeSort, setActiveSort] = useState("descTime");
    const [activeType, setActiveType] = useState("all");
    const [decks, setDecks] = useState([]);
    const [allPlayers, setAllPlayers] = useState([]);
    const [activeServers, setActiveServers] = useState([]);

    useEffect(() => {
        GetMatchReports();
        LoadDecks();
        getplayers();
        GetServers();
        const channel = supabase
            .channel('matchreports')
            .on(
                'postgres_changes',
                {
                    event: '*',
                    schema: 'public',
                    table: "matchreports"
                },
                (payload) => GetMatchReports()
            )
            .subscribe()
        return () => {
            supabase.removeChannel(channel);
        };
    }, []);

    async function GetServers(){
        await supabase
            .from('activeservers')
            .select("*")
            .then(data => {
                if(data.data && data.data.length > 0){
                    setActiveServers(data.data);
                } else {
                    setActiveServers([]);
                }
            });
    }

    async function LoadDecks(){
        setLoadingDecks(true);
        await supabase
            .from('decks')
            .select()
            .then(data => {
               if(data.data && data.data.length > 0){
                   setDecks(data.data);
                   setLoadingDecks(false);
               } else {
                   setDecks([]);
                   setLoadingDecks(false);
               }
            });
    }

    async function getplayers() {
        try {
            const { data, error } = await supabase
                .from('profiles')
                .select()
            if(error){

            } else {
                let profs = data;
                profs.sort((a, b) => isOnline(a.user_id) < isOnline(b.user_id) ? 1 : -1);
                setAllPlayers(profs)
            }
        } catch (error) {

        }
    }

    function isOnline(str){
        let found = false;
        if(users) {
            for(let i = 0; i < users.length; i++){
                if(users[i].id === str) found = true;
            }
            return found;
        } else return false;
    }

    function SortPlayers(str, desc){
        let profs = [];

        allPlayers.forEach(el => profs.push(el));
        switch (str) {
            case "online":
                profs.sort((a, b) => isOnline(a.user_id) < isOnline(b.user_id) ?  (desc ?  -1 : 1) : (desc ?  1 : -1) );
                setAllPlayers(profs);
                break;
            case "name":
                profs.sort((a, b) => a.username.toLowerCase() < b.username.toLowerCase() ? (desc ?  -1 : 1) : (desc ?  1 : -1));
                setAllPlayers(profs);
                break;
            case "id":
                profs.sort((a, b) => a.user_id.toLowerCase() < b.user_id.toLowerCase() ? (desc ?  -1 : 1) : (desc ?  1 : -1));
                setAllPlayers(profs);
                break;
            case "level":
                profs.sort((a, b) => a.level < b.level ? (desc ?  -1 : 1) : (desc ?  1 : -1));
                setAllPlayers(profs);
                break;
            case "last":
                profs.sort((a, b) => a.lastlogin < b.lastlogin ? (desc ?  -1 : 1) : (desc ?  1 : -1));
                setAllPlayers(profs);
                break;
            case "wins":
                profs.sort((a, b) => a.wins < b.wins ? (desc ?  -1 : 1) : (desc ?  1 : -1));
                setAllPlayers(profs);
                break;
            case "losses":
                profs.sort((a, b) => a.losses < b.losses ? (desc ?  -1 : 1) : (desc ?  1 : -1));
                setAllPlayers(profs);
                break;
            default:
                profs.sort((a, b) => a.online < b.online ? (desc ?  -1 : 1) : (desc ?  1 : -1));
                setAllPlayers(profs);
        }
    }

    function SortedReports(reps){
        switch(activeSort){
            case "ascTime":
                reps.sort((a, b) => a.startTime < b.startTime ? -1 : 1);
                break;
            case "descTime":
                reps.sort((a, b) => a.startTime < b.startTime ? 1 : -1);
                break;
        }
        return reps;
    }

    async function DeleteReport(mid){
        await supabase
            .from('matchreports')
            .delete()
            .eq('id', mid).then(result => {
                if(result.error){
                    toast.error("Failed to delete the match report.", {position: "bottom-right"});
                } else {
                    toast.success("Successfully deleted the match report.", {position: "bottom-right"});
                    setActiveReport(null);
                }
            })
    }

    useEffect(() => {
       if(newreports && newreports.length > 0) GetMatchDeploys();
       else setLoadingMR(false);
    }, [newreports]);

    async function GetMatchDeploys() {
        let reps = [];
        if(newreports && newreports.length > 0) {
            for(let i =0; i < newreports.length; i++){
                await supabase
                    .from('cardstats')
                    .select()
                    .eq("matchid", newreports[i].MatchID)
                    .then(data => {
                        let rep = newreports[i];
                        rep.rcardsDeployed = [];
                        rep.actionsDeployed = [];
                        rep.unitDeployed = [];
                        if(data.data && data.data.length > 0){
                            for(let i2 = 0; i2 < data.data.length; i2++){
                                switch(data.data[i2].cardType){
                                    case "Action":
                                        rep.actionsDeployed.push(data.data[i2]);
                                        break;
                                    case "Unit":
                                        rep.unitDeployed.push(data.data[i2]);
                                        break;
                                    case "Resource":
                                        rep.rcardsDeployed.push(data.data[i2]);
                                        break;
                                }
                            }
                            reps.push(rep);
                        } else {
                            let rep = newreports[i];
                            rep.rcardsDeployed = [];
                            rep.actionsDeployed = [];
                            rep.unitDeployed = [];
                            reps.push(rep);
                        }
                    })
            }
            setReports(reps);
            setLoadingMR(false);
        } else {
            setReports(reps);
            setLoadingMR(false);
        }
    }

    async function GetMatchReports() {
        setLoadingMR(true);
        try {
            const { data, error } = await supabase
                .from('matchreports')
                .select()
            if(error){
                setLoadingMR(false);
            } else {
                setNewReports(data);
            }
        } catch (error) {
            setLoadingMR(false);
        }
    }

    return (
        <AdminContext.Provider
            value={{
                loadingRM,
                reports,
                GetMatchReports,
                activeReport,
                setActiveReport,
                DeleteReport,
                activeSort,
                setActiveSort,
                SortedReports,
                activeType,
                setActiveType,
                decks,
                loadingDecks,
                LoadDecks,
                allPlayers,
                SortPlayers,
                isOnline,
                GetServers,
                activeServers
            }}>
            {children}
        </AdminContext.Provider>
    )
}

const useAdminContext = () => useContext(AdminContext);

export { AdminContext as default, AdminContextProvider, useAdminContext };