import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";

export default function PrivacyModal(props){
    const {privOpen, setPrivOpen} = props;
    const bgcolor = "bg-slate-600 text-[#b9f3e0]";

    return (
        <Dialog
            open={privOpen}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            className={"bg-slate-800 bg-opacity-60 text-[#b9f3e0]"}
        >
            <DialogTitle className={bgcolor} id="scroll-dialog-title">
                Privacy Notice
            </DialogTitle>
            <DialogContent className={bgcolor}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                    className={bgcolor}
                >
                    By clicking agree you agree that you are downloading and starting the
                    WebGL game client.
                </DialogContentText>
            </DialogContent>
            <DialogActions className={bgcolor}>
                <Button onClick={()=> {setPrivOpen(false);}}>Agree</Button>
            </DialogActions>
        </Dialog>
    );
}