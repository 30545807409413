import React, {useEffect, useState} from "react";
import Axios from "axios";
import {dblink} from "../App";
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from "@mui/material/Tooltip";
import {useAppContext} from "../contexts/appContext";
import {useAdminContext} from "../contexts/adminContext";
import {supabase} from "../utils/supabase";
import {useDisclosure} from "@mantine/hooks";
import {Modal, Button, Code, TextInput, Popover } from '@mantine/core';
import toast from "react-hot-toast";


export function Dashboard(props) {
    const {id} = useAppContext();
    const {GetServers, activeServers} = useAdminContext();
    return (
        <div className={"flex flex-row w-full h-full overflow-y-hidden"}>
            <div className={"flex flex-col w-full h-full p-1 lg:p-10 overflow-y-hidden"}>
                <div
                    className={"flex flex-col w-full h-full border border-[#51b78c] border-opacity-10 overflow-y-hidden items-center justify-center"}>
                    <div className={"flex flex-row h-12 w-full bg-slate-900 text-slate-300 items-center justify-between"}>
                        <div className={"flex flex-row h-10 w-10 items-center justify-center"}> </div>
                        <p className={"flex flex-row h-12 w-auto items-center justify-center"}>Active Servers</p>
                        <div className={"flex flex-row h-10 w-10 items-center justify-center"}
                             onClick={() => {
                                 GetServers();
                             }}>
                            <Tooltip title="Refresh List" placement="right">
                                <RefreshIcon/>
                            </Tooltip>
                        </div>
                    </div>
                    <ServersHeader/>
                    <div className={"flex flex-col text-xs lg:text-sm h-full w-full overflow-y-auto"}>
                        {activeServers.map(el => <ServerEntry key={el.serverID} serverentry={el}/>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

function SecondsToReadable(secs){
    let day = 86400;
    let hour = 3600;
    let minute = 60;
    let daysout = Math.floor(secs / day);
    let hoursout = Math.floor((secs - daysout * day)/hour);
    let minutesout = Math.floor((secs - daysout * day - hoursout * hour)/minute);
    let secondsout = secs - daysout * day - hoursout * hour - minutesout * minute;
    return daysout + " days " + hoursout + " hours " + minutesout + " minutes."
}

function ServersHeader(props) {
    return (
        <div
            className={"flex flex-col w-full text-xs lg:text-sm border border-[#51b78c] border-opacity-40 h-12 bg-slate-800 bg-opacity-40"}
        >
            <div className={"flex flex-row h-12 w-full text-white items-center justify-center"}>
                <div
                    className={"flex flex-row h-full w-3/6 lg:w-2/6 border-r border-[#51b78c] border-opacity-40 items-center justify-center"}>
                    ServerName
                </div>
                <div
                    className={"flex flex-row h-full w-1/6 border-r border-[#51b78c] border-opacity-40 items-center justify-center"}>
                    Players
                </div>
                <div
                    className={"flex flex-row h-full w-1/6 border-r border-[#51b78c] border-opacity-40 items-center justify-center"}>
                    Matches
                </div>
                <div
                    className={"hidden lg:flex flex-row h-full w-1/6 border-r border-[#51b78c] border-opacity-40 items-center justify-center"}>
                    In Queue
                </div>
                <div className={"flex flex-row h-full w-1/6 items-center justify-center"}>
                    Version
                </div>
            </div>
        </div>
    )
}

function ServerEntry(props) {
    const {serverentry} = props;
    const [expanded, setExpanded] = useState(false);
    const [apikey, setApikey] = useState("");
    const [serverLink, setServerLink] = useState("");
    const [opened, { open, close }] = useDisclosure(false);
    const [logLoading, setLogLoading] = useState(true);
    const [log, setLog] = useState("");

    const [downtime, setDowntime] = useState("15");
    useEffect(() => {
        if(serverentry){
            setServerLink("http://" + serverentry.serverAddress + ":" + serverentry.apiport + "/");
            GetKey();
        }
    }, [serverentry]);

    useEffect(() => {
        if(opened){
            GetLog();
        }
    }, [opened])

    async function GetKey(){
        await supabase
            .from('apikeys')
            .select("*")
            .eq("serverid", serverentry.serverID)
            .then(data => {
                if(data.data && data.data.length > 0){
                    setApikey(data.data[0].apikey);
                } else {
                    setApikey("");
                }
            });
    }

    function GetLog(){
        window.open(serverLink + "log?key=" + apikey, '_blank').focus();
    }

    async function InitiateShutdown(){
        window.open(serverLink + "shutdown?key=" + apikey + "?time=" + downtime, '_blank').focus();
    }

    return (
        <div key={serverentry.id} className={"flex flex-col w-full border-b border-[#51b78c] border-opacity-40 " + (expanded ? "h-24 bg-[#3b6f5e26]" : "h-12 bg-[#3b6f5e26] bg-opacity-40")}>
            <div className={"flex flex-row h-12 w-full text-white"} onClick={() => {
                setExpanded(!expanded);
            }}>
                <div
                    className={"flex flex-row h-full w-3/6 lg:w-2/6 items-center justify-around border-r border-[#51b78c] border-opacity-40"}>
                    <div
                        className={"flex flex-row w-4 h-4 lg:w-6 lg:h-6 rounded-full " + (serverentry.online ? "bg-green-400" : "bg-red-500")}></div>
                    <div>{serverentry.ServerName}</div>
                </div>
                <div
                    className={"flex flex-row h-full w-1/6 items-center justify-center border-r border-[#51b78c] border-opacity-40"}>
                    {serverentry.onlinePlayers}
                </div>
                <div
                    className={"flex flex-row h-full w-1/6 items-center justify-center border-r border-[#51b78c] border-opacity-40"}>
                    {serverentry.activeMatches}
                </div>
                <div
                    className={"hidden lg:flex flex-row h-full w-1/6 items-center justify-center border-r border-[#51b78c] border-opacity-40"}>
                    {serverentry.playerInQueue}
                </div>
                <div
                    className={"flex flex-row h-full w-1/6 items-center justify-center border-r border-[#51b78c] border-opacity-40"}>
                    {serverentry.version}
                </div>
            </div>
            <div
                className={(expanded ? "flex" : "hidden") + " flex-row h-12 w-full text-white border-t border-[#51b78c] border-opacity-20"}>
                <div className={"flex flex-row h-full w-1/4 items-center justify-center"}>
                    {expanded ? "Queue:  " + serverentry.playerInQueue : ""}
                </div>
                <div className={"flex flex-row h-full w-2/4 items-center justify-center"}>
                    {"Uptime:  " + SecondsToReadable(serverentry.uptime)}
                </div>
                <div className={"flex flex-row h-full w-1/4 items-center justify-around py-4"}>
                    <Popover width={200} position="bottom" withArrow shadow="md">
                        <Popover.Target>
                            <Button color={"red"} >Initiate Shutdown</Button>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <TextInput onChange={(e) => setDowntime(e.currentTarget.value)} value={downtime} label={"Expected Downtime"}></TextInput>
                            <Button color={"red"} onClick={() => {InitiateShutdown();}}>Notify and Shutdown</Button>
                        </Popover.Dropdown>
                    </Popover>
                    <Button color={"grey"} onClick={GetLog}>Show Log</Button>
                </div>
            </div>
        </div>
    )
}
