import React, {useEffect, useState} from "react";
import {StatsContextProvider, useStatsContext} from "../contexts/statisticsContext";
import {bordercolor} from "../theme";
import CardDeploys from "./carddeploys";
import MatchReports from "./matchreports";
import StatsDash from "./statsdash";
import {useAppContext} from "../contexts/appContext";
const selbuttonCol = "bg-[#4CB190C3] border border-[#3b6f5e26]"
const buttonCol = "bg-[#3b6f5e26] border border-[#4CB1904A]"


export default function Statistics() {
    const [openStatPage, setOpenStatPage] = useState("dash");
    const [hoverId, setHoverID] = useState("");
    const {cardsdate} = useAppContext();

    const menubutton = (page, title) => {
        return (
            <div onClick={() => {
                setOpenStatPage(page)
            }}
                 className={(openStatPage === page ? selbuttonCol : buttonCol) + " flex flex-row w-40 h-10 cursor-pointer items-center justify-center"}>
                {title}
            </div>)
    }

    return (
        <StatsContextProvider>
            <div className={"flex flex-col h-full w-full overflow-hidden"}>
                <div className={"flex flex-col h-full w-full overflow-hidden"}>
                    <div className={"flex flex-row h-14 w-full justify-center items-center gap-4 text-[#51b78c]  bg-[#0a0f13]"}>
                        {menubutton("dash", "Dashboard")}
                        {menubutton("deploys", "Card Deployments")}
                        {menubutton("matches", "Match Reports")}
                    </div>
                    {openStatPage === "deploys" && <CardDeploys hoverId={hoverId} setHoverID={setHoverID}/>}
                    {openStatPage === "matches" && <MatchReports hoverId={hoverId} setHoverID={setHoverID}/>}
                    {openStatPage === "dash" && <StatsDash/>}
                </div>
                {hoverId !== "" &&
                    <div onMouseLeave={() => setHoverID("")}
                         className={"absolute flex flex-col min-h-40 h-auto w-[400px] left-4 top-[20%] p-3 overflow-hidden self-center justify-self-center bg-[#0a0f13] rounded-2xl border " + bordercolor}>
                        <img className={"h-auto w-full rounded-2xl"} alt={"preview" + hoverId}
                             onMouseLeave={() => setHoverID("")}
                             src={"/cards/" + hoverId + ".png?key=" + cardsdate}/>
                    </div>}
            </div>
        </StatsContextProvider>
    )
}