import React, {useEffect, useMemo, useState} from "react";
import {useDbContext} from "./contexts/dbContext";
import {useAppContext} from "./contexts/appContext";
import {DeckBuilder} from "./deckbuilder/deckbuilder";
import AuthPanel, {ResetPasswordPanel} from "./components/authpanel";
import TOSModal from "./components/tos";
import PrivacyModal from "./components/privacy";
import {Link, Navigate, Route, Routes, useLocation} from "react-router-dom";
import Home from "./pages/home";
import RuleBook from "./pages/rulebook";
import TreasuryModal from "./pages/treasury";
import AccountPage from "./pages/accountpage";
import Tooltip from "@mui/material/Tooltip";
import HomeIcon from "@mui/icons-material/Home";
import ChatIcon from '@mui/icons-material/Chat';
import {CollectionsBookmark, PlayArrow, Rule} from "@mui/icons-material";
import {bordercolor} from "./theme";
import {SocialPanel} from "./social/socialpanel";
import AdminPanel from "./admin/adminpanel";
import RotePlayer from "./pages/roteplayer";
import Statistics from "./statistics/statistics";
import CardSheet from "./deckbuilder/cardsheet";
import {Badge} from "@mui/material";
import {useSocialContext} from "./contexts/socialContext";
import PublicDecks from "./deckbuilder/publicDecks";
import InGame from "./pages/ingame";
import Profile from "./pages/profile";
import Privacy from "./pages/privacy";
import TOS from "./pages/tos";
import {Affix, Blockquote, Button, Drawer, ScrollArea, Transition, List } from "@mantine/core";
import {useDisclosure} from "@mantine/hooks";
import FeedIcon from '@mui/icons-material/Feed';
import ChatWindow from "./social/chatwindow";

export const dblink = "https://services.nanoserver.xyz";

function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}

export default function App() {
    const {
        loadingInitial,
        webGLStarted,
        setWebGLStarted,
        openPage,
        hideMenu,
        setHideMenu,
        id,
        inMatch,
        u6,
        cardsOnly,
        playConfirm,
        session,
        isAdmin
    } = useAppContext();
    const {myDecks} = useDbContext();
    const [spanelOpen, setSPanelOpen] = useState(false);
    const [tosOpen, setTosOpen] = useState(false);
    const [privOpen, setPrivOpen] = useState(false);
    const [hoverId, setHoverID] = useState("");
    const [hoverType, setHoverType] = useState("");
    const [treasuryOpen, setTreasuryOpen] = useState(false);
    let query = useQuery();
    let route = useLocation();
    useEffect(() => {
        if(!session) {
            setSPanelOpen(false);
            setTreasuryOpen(false);
        }
    }, [session])

    if (route.pathname.split("/")[1] === "ingame") {
        return (
            <div onContextMenuCapture={(e) => {
                e.preventDefault();
            }} className={"flex flex-col font-noto overscroll-none h-screen w-screen overflow-hidden text-slate-200"}>
                <img className={"absolute flex-row h-screen w-screen z-0 "}
                     src={"gradient_bg01.png"} alt={"bg"}/>
                <div className={"absolute h-full w-full flex flex-col overflow-hidden"}>
                    <Routes>
                        {!session ? <Route path='/ingame' element={<InGame reqtoken={query.get("reqtoken")}
                                                                           acctoken={query.get("acctoken")}/>}/> :
                            <Route path='/ingame' element={<DeckBuilder cardsOnly={false} setHoverType={setHoverType}
                                                                        setHoverID={setHoverID} hoverId={hoverId}/>}/>
                        }
                    </Routes>
                </div>
                {hoverId !== "" && <div
                    className={"absolute flex flex-col min-h-40 h-auto w-[400px] p-3 overflow-hidden self-center justify-self-center bg-[#0a0f13] rounded-2xl border " + bordercolor + (hoverType === "decks" ? " right-[10px] top-[9%] " : " right-[405px] top-[30%] ")}>
                    <img className={"h-auto w-full rounded-2xl"} alt={"preview" + hoverId}
                         src={"/cards/" + hoverId + ".png"}/>
                </div>}
            </div>
        )
    } else if (loadingInitial) return (<div>

    </div>)
    else {
        if (route.pathname.split("/")[1] === "cardbrowser" || route.pathname.split("/")[1] === "cb") {
            return (
                <div onContextMenuCapture={(e) => {
                    e.preventDefault();
                }}
                     className={"flex flex-col font-noto overscroll-none h-screen w-screen overflow-hidden text-slate-200"}>
                    <img className={"absolute flex-row h-screen w-screen z-0 "}
                         src={"gradient_bg01.png"} alt={"bg"}/>
                    <div className={"absolute h-full w-full flex flex-col overflow-hidden"}>
                        <Routes>
                            <Route path='/cb'
                                   element={<DeckBuilder cardsOnly={true} setHoverType={setHoverType}
                                                         setHoverID={setHoverID} hoverId={hoverId}/>}/>
                            <Route path='/cardbrowser'
                                   element={<DeckBuilder cardsOnly={true} setHoverType={setHoverType}
                                                         setHoverID={setHoverID} hoverId={hoverId}/>}/>
                        </Routes>
                    </div>
                </div>
            )
        } else {
            return (
                <div onContextMenuCapture={(e) => {
                    e.preventDefault();
                }}
                     className={"flex flex-col font-noto overscroll-none h-screen w-screen overflow-hidden text-slate-200"}>
                    {!session && <div className={"absolute h-screen w-full"}>
                        <img alt={"bg"} className={"opacity-90 h-full w-full"}
                             src={"./Sungrace-Gardens-e1706124188182.png"}/>
                    </div>}
                    <img className={"absolute flex-row h-screen w-screen z-0 " + (!hideMenu ? " flex" : " hidden")}
                         src={"gradient_bg01.png"} alt={"bg"}/>
                    <TopMenu treasuryOpen={treasuryOpen} setTreasuryOpen={setTreasuryOpen}
                             setSPanelOpen={setSPanelOpen} spanelOpen={spanelOpen}/>
                    <div className={"relative h-full w-full flex flex-col overflow-hidden "}>
                        <Routes>
                            {session ? <Route path='/' element={<Home friendid={query.get("friendid")}/>}/> :
                                <Route path='/' element={<AuthPanel/>}/>}
                            <Route path='/resetpass' element={<ResetPasswordPanel />}/>
                            <Route path='/privacy' element={<Privacy/>}/>
                            <Route path='/tos' element={<TOS/>}/>
                            <Route path='/rules' element={<RuleBook/>}/>
                            <Route path='/profile' element={<Profile playerid={query.get("playerid")}/>}/>
                            {session && <Route path='/chat' element={<ChatWindow />}/>}
                            <Route path='/cardbrowser' element={<DeckBuilder cardsOnly={true} setHoverType={setHoverType} setHoverID={setHoverID} hoverId={hoverId}/>}/>
                            {session && <Route path='/decks' element={<PublicDecks setHoverType={setHoverType}  fullpage={true} setHoverID={setHoverID} hoverId={hoverId} hideMenu={hideMenu}/>}/>}
                            {session && <Route path='/d' element={<PublicDecks setHoverType={setHoverType}  fullpage={true} setHoverID={setHoverID} hoverId={hoverId} hideMenu={hideMenu}/>}/>}
                            {session && <Route path='/builder' element={<DeckBuilder cardsOnly={false} setHoverType={setHoverType} setHoverID={setHoverID} hoverId={hoverId}/>}/>}
                            {session && <Route path='/deckbuilder' element={<DeckBuilder cardsOnly={false} setHoverType={setHoverType}  setHoverID={setHoverID} hoverId={hoverId}/>}/>}
                            {session && <Route path='/db' element={<DeckBuilder setHoverType={setHoverType} cardsOnly={false}  setHoverID={setHoverID} hoverId={hoverId}/>}/>}
                            {session && <Route path='/account' element={<AccountPage/>}/>}
                            {session && <Route path='/a' element={<AccountPage/>}/>}
                            {session && <Route path='/statistics' element={<Statistics/>}/>}
                            {session && <Route path='/s' element={<Statistics/>}/>}
                            {session && <Route path='/cardsheet' element={<CardSheet/>}/>}
                            {session && <Route path='/cs' element={<CardSheet/>}/>}
                            {session && isAdmin && <Route path='/admin' element={<AdminPanel/>}/>}
                            {session && isAdmin && <Route path='/a' element={<AdminPanel/>}/>}
                            <Route path="*" element={<Navigate to="/"/>}/>
                        </Routes>
                    </div>
                    {/*openPage === "main" && !inMatch && <NewsCarousel setOpenPage={setOpenPage} />*/}
                    {/* inMatch && <MatchChat matchID={matchID} inMatch={inMatch} /> */}
                    <SocialPanel panelOpen={spanelOpen} setPanelOpen={setSPanelOpen}/>
                    {treasuryOpen && <TreasuryModal treasuryOpen={treasuryOpen} setTreasuryOpen={setTreasuryOpen}/>}
                    <TOSModal setTosOpen={setTosOpen} tosOpen={tosOpen}/>
                    <PrivacyModal privOpen={privOpen} setPrivOpen={setPrivOpen}/>
                    {hoverId !== "" && <div
                        className={"absolute flex flex-col min-h-40 h-auto w-[400px] z-50 p-3 overflow-hidden self-center justify-self-center bg-[#0a0f13] rounded-2xl border " + bordercolor + (hoverType === "decks" ? " right-[10px] top-[9%] " : " right-[405px] top-[30%] ")}>
                        <img className={"h-auto w-full rounded-2xl"} alt={"preview" + hoverId}
                             src={"/cards/" + hoverId + ".png"}/>
                    </div>}
                </div>
            )

        }
    }
}

const sdeckstyle = "flex flex-col h-full w-[500px] bg-header items-center justify-start rounded-2xl px-4";
const sdeckbutton = "flex h-14 w-full justify-center items-center bg-secondary";

const selbuttonCol = "bg-[#4CB190C3] border border-[#3b6f5e26]"
const buttonCol = "bg-[#3b6f5e26] border border-[#4CB1904A]"
const mainbutton = "flex flex-row text-center !text-[#51b78c] h-12 justify-center items-center w-[120px] hover:opacity-75  "

const col = "rgb(25,48,37)";

function TopMenu(props) {
    const {setSPanelOpen, spanelOpen, setTreasuryOpen, treasuryOpen} = props;
    const {TotalCount} = useSocialContext();
    const {openPage, profile, playConfirm, session, isAdmin} = useAppContext();
    const {setDeckWindow, deckWindow} = useDbContext();
    return (
        <div
            className={`relative flex flex-row items-center bg-[#01130FF7] overflow-hidden w-full h-[80px] border-b justify-between ` +
                bordercolor}>
            <Link to={"/"} target={(openPage() === "game" && playConfirm) ? "_blank" : "_self"}>
                <img src="/logo192.png"
                     alt="Manacaster"
                     className="my-auto hidden lg:flex ml-2 h-12 w-auto hover:opacity-60"
                />
            </Link>
            <div className={"flex w-full h-full items-center justify-end flex-row select-none"}>
                <div className="hidden lg:flex w-auto h-full items-end justify-center gap-3 flex-row lg:pr-4 lg:pb-4">
                    <Tooltip title="Open Main Screen" placement="bottom">
                        <Link to={"/"} target={(openPage() === "game" && playConfirm) ? "_blank" : "_self"}>
                            <div
                                className={"flex flex-row text-center !text-[#51b78c] h-12 justify-center items-center w-12 hover:opacity-75  " +
                                    (openPage() === "/" ? selbuttonCol : buttonCol)}>
                                <HomeIcon/>
                            </div>
                        </Link>
                    </Tooltip>
                    {session && <Tooltip title="Open the public decks list." placement="bottom">
                        <Link to={openPage() === "builder" && deckWindow ? "/" : "/builder"} onClick={() => {
                            setDeckWindow(true);
                        }}>
                            <div className={mainbutton +
                                (openPage() === "builder" && deckWindow ? selbuttonCol : buttonCol)}>
                                Decks
                            </div>
                        </Link>
                    </Tooltip>}
                    {session && <Tooltip title="Open the Deck Builder." placement="bottom">
                        <Link to={openPage() === "builder" && !deckWindow ? "/" : "/builder"} target={"_self"}
                              onClick={() => {
                                  setDeckWindow(false);
                              }}>
                            <div className={mainbutton +
                                (openPage() === "builder" && !deckWindow ? selbuttonCol : buttonCol)}>
                                DeckBuilder
                            </div>
                        </Link>
                    </Tooltip>}
                    {/*<Tooltip title="Open the Rulebook" placement="bottom">
                        <Link to={openPage() === "rules" ? "/" : "/rules"} target={(openPage() === "game" && playConfirm) ? "_blank" : "_self"}>
                            <div className={mainbutton +
                                (openPage() === "rules" ? selbuttonCol : buttonCol)} >
                                Rulebook
                            </div>
                        </Link>
                    </Tooltip>*/}
                </div>
                {session && profile && <div
                    className="flex w-full lg:w-[400px] h-full items-center border-l border-[#51b78c] border-opacity-40 bg-[#222322FF] justify-center flex-row gap-1">
                    <div className={"flex w-full h-full bg-black bg-opacity-40  items-center justify-center flex-row gap-1"}>


                        <Tooltip title="Open your treasury" placement="bottom">
                            <div onClick={() => {
                                setTreasuryOpen(!treasuryOpen);
                            }}
                                 className={"flex flex-row ml-2 cursor-pointer hover:bg-opacity-60 h-14 w-2/5 gap-2 justify-center bg-opacity-40 items-center self-center justify-self-center rounded-lg  border border-[#4CB1904A] "
                                     + (treasuryOpen ? "bg-green-300" : "bg-black")
                                 }>
                                <div className={"flex h-full w-auto justify-center items-center"}>
                                    <img className={"flex h-8 w-auto"} alt={"gold"} src={"/gold.png"}/>
                                </div>
                                <div className={"flex h-full w-1/2 justify-center items-center"}>
                                    {profile && profile.gold}
                                </div>
                            </div>
                        </Tooltip>
                        <div
                            className={"flex flex-row hover:bg-opacity-60 h-14 cursor-pointer w-3/5 gap-2 justify-around bg-opacity-40 items-center rounded-lg border border-[#4CB1904A] "
                                + (spanelOpen ? "bg-green-300" : "bg-black")}
                            onClick={() => {
                                setSPanelOpen(!spanelOpen);
                            }}>
                            <p>{profile && profile.username}</p>
                            <Badge badgeContent={TotalCount()} color="success"
                                   anchorOrigin={{vertical: 'top', horizontal: "left"}}>
                                <ChatIcon fontSize={"medium"}/>
                            </Badge>
                        </div>
                        <div
                            className={"flex flex-row w-full lg:w-auto h-full justify-end lg:justify-around gap-1 lg:gap-2 items-center pr-2"}>
                            <div className={"flex lg:hidden flex-row w-auto h-14 gap-1 justify-center items-center"}>
                                <Tooltip title="Open Main Screen" placement="bottom">
                                    <Link to={"/"} target={(openPage() === "game" && playConfirm) ? "_blank" : "_self"}>
                                        <div
                                            className={"flex flex-row text-center !text-[#51b78c] h-12 justify-center items-center w-12 hover:opacity-75  " +
                                                (openPage() === "/" ? selbuttonCol : buttonCol)}>
                                            <HomeIcon/>
                                        </div>
                                    </Link>
                                </Tooltip>
                                <Tooltip title="Open Deck Builder" placement="bottom">
                                    <Link to={openPage() === "decks" ? "/" : "/decks"}
                                          target={(openPage() === "game" && playConfirm) ? "_blank" : "_self"}>
                                        <div
                                            className={"flex flex-row text-center !text-[#51b78c] h-12 justify-center items-center w-12 hover:opacity-75  " +
                                                (openPage() === "decks" ? selbuttonCol : buttonCol)}>
                                            <CollectionsBookmark/>
                                        </div>
                                    </Link>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}
