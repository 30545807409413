import React, {useEffect, useState} from "react";
import Axios from "axios";
import {dblink} from "../App";
import {Chip} from "@mui/material";
import {useAppContext} from "../contexts/appContext";
import {useSocialContext} from "../contexts/socialContext";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {supabase} from "../utils/supabase";
import {useAdminContext} from "../contexts/adminContext";

export function OnlinePlayersTable(props) {
    const {id} = useAppContext();
    const {users} = useSocialContext();
    const {allPlayers, SortPlayers, isOnline} = useAdminContext();

    return (
        <div className={"flex flex-row w-full h-full text-xs lg:text-sm bg-[#131a20] bg-opacity-40"}>
            <div className={"flex h-full flex-col w-full overflow-hidden"}>
                <div className="flex h-14 flex-row gap-2 w-full px-4 py-2">

                </div>
                <div className={"flex flex-col w-full overflow-hidden h-full p-4 lg:p-10"}>
                    <div className="flex overflow-y-auto w-full border border-[#51b78c] border-opacity-40">
                        <table className="table w-full table-xs table-pin-rows table-pin-cols justify-center items-center">
                            <PlayersHeader SortPlayers={SortPlayers} />
                            <tbody>
                            {allPlayers && allPlayers.map((el, ind) =>
                                <PlayerItem isOnline={isOnline} key={ind} entry={el} />)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

function PlayersHeader(props) {
    const {SortPlayers} = props;
    return (
        <thead>
        <tr>
            <th>State <ArrowDropDownIcon onClick={()=> SortPlayers("online", true)}/> <ArrowDropUpIcon onClick={()=> SortPlayers("online", false)}/> </th>
            <th>ID<ArrowDropDownIcon onClick={()=> SortPlayers("id", true)}/> <ArrowDropUpIcon onClick={()=> SortPlayers("id", false)}/></th>
            <th>Name<ArrowDropDownIcon onClick={()=> SortPlayers("name", true)}/> <ArrowDropUpIcon onClick={()=> SortPlayers("name", false)}/></th>
            <th>Level<ArrowDropDownIcon onClick={()=> SortPlayers("level", true)}/> <ArrowDropUpIcon onClick={()=> SortPlayers("level", false)}/></th>
            <th>Last Login<ArrowDropDownIcon onClick={()=> SortPlayers("last", true)}/> <ArrowDropUpIcon onClick={()=> SortPlayers("last", false)}/></th>
            <th>Wins<ArrowDropDownIcon onClick={()=> SortPlayers("wins", true)}/> <ArrowDropUpIcon onClick={()=> SortPlayers("wins", false)}/></th>
            <th>Losses<ArrowDropDownIcon onClick={()=> SortPlayers("losses", true)}/> <ArrowDropUpIcon onClick={()=> SortPlayers("losses", false)}/></th>
        </tr>
        </thead>
    )
}

const playerentrystyle = "flex-row text-center justify-center items-center border-r border-[#51b78c] border-opacity-40";

function PlayerItem(props) {
    const {entry, isOnline} = props;
    return (
        <tr className={"border border-[#51b78c] border-opacity-40"}>
            <td className={playerentrystyle}>{isOnline(entry.user_id) ? <Chip label="Online" color="primary"/> : <Chip label="Offline" color="error"/>}</td>
            <th className={playerentrystyle}>{entry.user_id}</th>
            <td className={playerentrystyle}>{entry.username}</td>
            <td className={playerentrystyle}>{entry.level}</td>
            <td className={playerentrystyle}>{entry.lastlogin}</td>
            <td className={playerentrystyle}>{entry.wins}</td>
            <td className={playerentrystyle}>{entry.losses}</td>
        </tr>
    )
}