import {createContext, useContext, useEffect, useMemo, useState} from "react";
import secureLocalStorage from "react-secure-storage";
import {useLocation} from "react-router-dom";
import {gethDateTime, supabase} from "../utils/supabase";

const AppContext = createContext({});


// @ts-ignore
const AppContextProvider = ({ children }) => {
    const [session, setSession] = useState(null);
    const [email, setEmail] = useState("");
    const [id, setID] = useState("");
    const [profile, setProfile] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [playConfirm, setPlayConfirm] = useState(false);
    const [cardsOnly, setCardsOnly] = useState(false);
    const [inMatch, setIsInMatch] = useState(false);
    const [matchID, setMatchID] = useState("");
    const [u6,setU6] = useState(secureLocalStorage.getItem("u6"));
    const [hideMenu, setHideMenu] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(false);
    const [loadingInitial, setLoadingInitial] = useState(true);
    const [webGLStarted, setWebGLStarted] = useState(false);
    const [discord, setDiscord] = useState(null);
    const [lastMatch, setLastMatch] = useState();
    const cardX = 814;
    const cardY = 1110;
    const cardsdate = "031124";
    const version = "0.1.5b10";
    const company = "Manacaster Games";
    const product = "Manacaster";
    let route = useLocation();

    useEffect(() => {
        if (isInitialLoad) {
            supabase.auth.getSession().then(({data: {session: any}}) => {
                setSession(session);
            })
        }
        const sub = supabase.auth.onAuthStateChange((_event, _session) => {
            setSession(_session);
            if (_session) {
                if(!_session.user.user_metadata.user_name) {
                    GetProfile(_session.user.id, _session.user.user_metadata.full_name, _session.user.user_metadata.email);
                    CheckDiscord();
                } else {
                    GetProfile(_session.user.id, _session.user.user_metadata.user_name, _session.user.user_metadata.email);
                    CheckDiscord();
                }
            } else {
                setID("");
                setEmail("");
                setProfile(null);
                setIsAdmin(false);
                if (isInitialLoad) setIsInitialLoad(false);
                if (loadingInitial) setLoadingInitial(false);
            }
        })
        return (() => {
            sub.data.subscription.unsubscribe();

        })
    }, []);

    async function CheckDiscord(){
       await supabase.auth.getUserIdentities().then(re => {
            if(re.error){
                setDiscord(null);
            } else {
                const discord = re.data.identities.find((identity) => identity.provider === 'discord')
                if(discord) setDiscord(discord);
                else setDiscord(null);
            }
        })
    }

    function DateTag(){
        let date = new Date();
        let d = date.getDate();
        let m = date.getMonth();
        let y = date.getFullYear();
        return d.toString() + m.toString() + y.toString();
    }

    async function CreateProfile(_id, _username, _email){
        if(_id === "") {
            setID("");
            setEmail("");
            setProfile(null);
            setIsAdmin(false);
            if(isInitialLoad) setIsInitialLoad(false);
            if(loadingInitial) setLoadingInitial(false);
            return;
        }

        const { data2, error2 } = await supabase
            .from('profiles')
            .insert({ username: _username, steamid: 0, gold: 150, lastlogin: gethDateTime(), banned: false, level: 1, goldlog: [{amount: 150, reason: "Registration", date: gethDateTime()}], lastdaily: "1", online: false   })
        if(error2){
            setID("");
            setEmail("");
            setProfile(null);
            setIsAdmin(false);
            if(isInitialLoad) setIsInitialLoad(false);
            if(loadingInitial) setLoadingInitial(false);
        } else {
            await GetProfile(_id, _username, _email);
        }
    }

    async function GetProfile(_id, _username, _email) {
        if(_id === "") {
            setID("");
            setEmail("");
            setProfile(null);
            setIsAdmin(false);
            if(isInitialLoad) setIsInitialLoad(false);
            if(loadingInitial) setLoadingInitial(false);
            return;
        }

        const { data, error } = await supabase
            .from('profiles')
            .select()
            .eq("user_id", _id);
        if(error){
            setID("");
            setEmail("");
            setProfile(null);
            setIsAdmin(false);
            if(isInitialLoad) setIsInitialLoad(false);
            if(loadingInitial) setLoadingInitial(false);
        } else {
            if(data.length > 0){
                setProfile(data[0]);
                setID(_id);
                setEmail(_email);
                try {
                    const { data, error } = await supabase.rpc('is_admin');
                    if(error){
                        setIsAdmin(false);
                    } else setIsAdmin(data);
                } catch (error){
                    setIsAdmin(false);
                }
                if(isInitialLoad) setIsInitialLoad(false);
                if(loadingInitial) setLoadingInitial(false);
            } else {
                await CreateProfile(_id, _username, _email);
            }
        }
    }

    const openPage = () => {
        if (route.pathname === "/") {
            return "/";
        } else {
            return route.pathname.split("/")[1];
        }
    }

    return (
        <AppContext.Provider
            value={{
                cardsOnly,
                playConfirm,
                setPlayConfirm,
                GetProfile,
                email,
                permissions,
                id,
                matchID,
                profile,
                inMatch,
                u6,
                setU6,
                openPage,
                hideMenu,
                setHideMenu,
                setEmail,
                setIsInMatch,
                setMatchID,
                isAdmin,
                session,
                webGLStarted,
                setWebGLStarted,
                loadingInitial,
                version,
                company,
                product,
                cardsdate,
                discord,
                CheckDiscord,
                cardX,
                cardY
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

const useAppContext = () => useContext(AppContext);

export { AppContext as default, AppContextProvider, useAppContext };