import {supabase} from "../utils/supabase";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import {useAppContext} from "./appContext";

const StatsContext = createContext({});

const StatsContextProvider = ({ children }) => {
    const {id} = useAppContext();
    const [reports, setReports] = useState([]);
    const [loadingRM, setLoadingMR] = useState(true);
    const [activeReport, setActiveReport] = useState(null);
    const [loadingStats, setLoadingStats] = useState(true);
    const [newreports, setNewReports] = useState([]);
    const [cardstats, setCardstats] = useState({ rcardsDeployed: [], actionsDeployed: [], unitDeployed: [] });
    const [cardTotalStats, setCardTotalStats] = useState({ rcardsDeployed: [], actionsDeployed: [], unitDeployed: [] });
    const [wins, setWins] = useState(0);
    const [losses, setLosses] = useState(0);
    const [totalPlayed, setTotalPlayed] = useState(0);
    const [activeSort, setActiveSort] = useState("descTime");
    const [activeType, setActiveType] = useState("all");

    function SortedReports(reps){
        switch(activeSort){
            case "ascTime":
                reps.sort((a, b) => a.startTime < b.startTime ? -1 : 1);
                break;
            case "descTime":
                reps.sort((a, b) => a.startTime < b.startTime ? 1 : -1);
                break;
        }
        return reps;
    }

    useEffect(() => {
        setLoadingMR(true);
        setLoadingStats(true);
        GetMatchReports();
        GetCardDeploys();
        const channel = supabase
            .channel('matchreports')
            .on('postgres_changes',
                {
                    event: '*',
                    schema: 'public',
                    table: "matchreports"
                },
                (payload) => GetMatchReports()
            )
            .subscribe()
        const channel2 = supabase
            .channel('cardstats')
            .on('postgres_changes',
                {
                    event: '*',
                    schema: 'public',
                    table: "cardstats"
                },
                (payload) => { GetCardDeploys(); }
            )
            .subscribe()
        return () => {
            supabase.removeChannel(channel);
            supabase.removeChannel(channel2);
        };
    }, []);

    useEffect(() => {
        if(cardstats) {
            TallyCardTotals();
        }
    }, [cardstats]);

    function TallyCardTotals(){
        setLoadingStats(true);
        let rep = {rcardsDeployed: [], actionsDeployed: [], unitDeployed: []};
        rep.rcardsDeployed = [];
        for(let i = 0; i < cardstats.rcardsDeployed.length; i++){
            let found = false;
            let crd = cardstats.rcardsDeployed[i];
            for(let i2 = 0; i2 <  rep.rcardsDeployed.length; i2++){
                if( rep.rcardsDeployed[i2].cardid === crd.cardid){
                    rep.rcardsDeployed[i2].amount++;
                    found = true;
                    break;
                }
            }
            if(!found){
                crd.amount = 1;
                rep.rcardsDeployed.push(crd);
            }
        }
        rep.actionsDeployed = [];
        for(let i = 0; i < cardstats.actionsDeployed.length; i++){
            let found = false;
            let crd = cardstats.actionsDeployed[i];
            for(let i2 = 0; i2 <  rep.actionsDeployed.length; i2++){
                if( rep.actionsDeployed[i2].cardid === crd.cardid){
                    rep.actionsDeployed[i2].amount++;
                    found = true;
                    break;
                }
            }
            if(!found){
                crd.amount = 1;
                rep.actionsDeployed.push(crd);
            }
        }
        rep.unitDeployed = [];
        for(let i = 0; i < cardstats.unitDeployed.length; i++){
            let found = false;
            let crd = cardstats.unitDeployed[i];
            for(let i2 = 0; i2 <  rep.unitDeployed.length; i2++){
                if( rep.unitDeployed[i2].cardid === crd.cardid){
                    rep.unitDeployed[i2].amount++;
                    found = true;
                    break;
                }
            }
            if(!found){
                crd.amount = 1;
                rep.unitDeployed.push(crd);
            }
        }
        setCardTotalStats(rep);
        setLoadingStats(false);
    }

    async function GetCardDeploys() {
        setLoadingStats(true);
        await supabase
            .from('cardstats')
            .select()
            .then(data => {
                let rep = {rcardsDeployed: [], actionsDeployed: [], unitDeployed: []};
                if (data.data && data.data.length > 0) {
                    for (let i2 = 0; i2 < data.data.length; i2++) {
                        switch (data.data[i2].cardType) {
                            case "Action":
                                rep.actionsDeployed.push(data.data[i2]);
                                break;
                            case "Unit":
                                rep.unitDeployed.push(data.data[i2]);
                                break;
                            case "Resource":
                                rep.rcardsDeployed.push(data.data[i2]);
                                break;
                        }
                    }
                    setCardstats(rep);
                    setLoadingStats(false);
                } else {
                    let rep = {rcardsDeployed: [], actionsDeployed: [], unitDeployed: []};
                    setCardstats(rep);
                    setLoadingStats(false);
                }
            })
    }

    useEffect(() => {
        if(newreports && newreports.length > 0) GetMatchDeploys();
        else setLoadingMR(false);
    }, [newreports]);

    function GetMatchDeploys() {
        let reps = [];
        let totwins = 0;
        let totlosses = 0;
        let tot = 0;
        if (newreports && newreports.length > 0) {
            for (let i = 0; i < newreports.length; i++) {
                if (newreports[i].winnerID === id) totwins++;
                else totlosses++;
                tot++;
                supabase
                    .from('cardstats')
                    .select()
                    .eq("matchid", newreports[i].MatchID)
                    .then(data => {
                        let rep = newreports[i];
                        rep.rcardsDeployed = [];
                        rep.actionsDeployed = [];
                        rep.unitDeployed = [];
                        if (data.data && data.data.length > 0) {
                            for (let i2 = 0; i2 < data.data.length; i2++) {
                                switch (data.data[i2].cardType) {
                                    case "Action":
                                        rep.actionsDeployed.push(data.data[i2]);
                                        break;
                                    case "Unit":
                                        rep.unitDeployed.push(data.data[i2]);
                                        break;
                                    case "Resource":
                                        rep.rcardsDeployed.push(data.data[i2]);
                                        break;
                                }
                            }
                            reps.push(rep);
                        } else {
                            let rep = newreports[i];
                            rep.rcardsDeployed = [];
                            rep.actionsDeployed = [];
                            rep.unitDeployed = [];
                            reps.push(rep);
                        }
                    })
            }
            setWins(totwins);
            setLosses(totlosses);
            setTotalPlayed(tot);
            setReports(reps);
            setLoadingStats(false);
            setLoadingMR(false);
        } else {
            setReports(reps);
            setLoadingStats(false);
            setLoadingMR(false);
        }
    }


    async function GetMatchReports() {
        setLoadingMR(true);
        try {
            await supabase
                .from('matchreports')
                .select()
                .or(`pOneID.eq.${id},pTwoID.eq.${id}`)
                .then(data => {
                    if(data.data && data.data.length > 0) {
                        setNewReports(data.data);
                    } else {
                        setNewReports([]);
                    }
                });
        } catch (error) {
            console.log(error);
            setLoadingMR(false);
        }
    }

    return (
        <StatsContext.Provider
            value={{
                loadingRM,
                reports,
                GetMatchReports,
                activeReport,
                setActiveReport,
                loadingStats,
                cardstats,
                cardTotalStats,
                SortedReports,
                activeSort,
                setActiveSort,
                wins,
                losses,
                totalPlayed,
                activeType,
                setActiveType
            }}>
            {children}
        </StatsContext.Provider>
    )
}

const useStatsContext = () => useContext(StatsContext);

export { StatsContext as default, StatsContextProvider, useStatsContext };