import React, {useEffect, useState} from "react";
import {useAppContext} from "../contexts/appContext";
import {Link} from "react-router-dom";
import {bordercolor} from "../theme";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";
import {useStatsContext} from "../contexts/statisticsContext";
import {Button} from "@mantine/core";

export default function SocialDash(props) {
    const {profile, openPage, discord, CheckDiscord, isAdmin} = useAppContext();

   async function LinkDiscord(){
        if(discord) return;
        const { data, error } = await supabase.auth.linkIdentity({ provider: 'discord' })
        if(error){

        } else {
            CheckDiscord();
        }
    }

    async function UnlinkDiscord(){
       if(!discord) return;
        const {
            data: { identities },
        } = await supabase.auth.getUserIdentities()

        const discordid = identities.find((identity) => identity.provider === 'discord')
        const { data, error } = await supabase.auth.unlinkIdentity(discordid)
    }

    const [text, setText] = useState("")
    return (
        <div className={"flex flex-col h-full w-full overflow-hidden"}>
            <div className={"flex flex-col items-center h-full w-full"}>
                <div className={"flex flex-col h-1/2 items-center w-full gap-10"}>
                    <div className={"flex flex-col w-full h-16 bg-[#3A3A3AFF] shadow-lg"}>
                        <p className={"flex flex-row w-full h-full justify-center items-center text-xl"}>{profile ? profile.username : ""}</p>
                    </div>
                    <div className={"flex flex-col h-auto items-center w-full gap-4"}>
                    <Link className={"flex flex-row h-10 justify-center items-center w-full"}
                            to={"/statistics"} target={"_self"}>
                            <Button color={openPage() === "statistics" ? "green" : "rgba(82, 82, 82, 1)"}>See statistics</Button>
                        </Link>
                        {isAdmin &&
                        <Link className={"flex flex-row h-10 justify-center items-center w-full"}
                              to={"/admin"} target={"_self"}>
                            <Button color={openPage() === "admin" ? "green" : "rgba(82, 82, 82, 1)"}>Administration</Button>
                        </Link>}
                    </div>
                </div>
                <div className={"flex flex-col w-[90%] h-[150px] justify-start items-start mb-10 border border-[#717171FF] shadow-md"}>
                    <div
                        className={"flex flex-row w-full h-10 border-b border-[#717171FF] p-2 justify-between items-center"}>
                        Discord
                        <Button size={"xs"} color={"rgba(82, 82, 82, 1)"} onClick={() => {
                            UnlinkDiscord();
                        }}>
                            Unlink
                        </Button>
                    </div>
                    {discord ?
                        <div className={"flex flex-row w-full h-full justify-center items-center"}>
                            <img className={"flex flex-row w-20 h-20"} src={discord.identity_data.avatar_url} alt={"avatar"}/>
                            <div className={"flex flex-col w-full h-full justify-center items-center"}>
                                <p>{discord.identity_data.full_name}</p>
                                <p>{discord.identity_data.provider_id}</p>
                            </div>
                        </div>
                        :
                        <Button onClick={() => { LinkDiscord(); }} className={"btn-ghost px-4 py-2"}>
                            Link Discord
                        </Button>
                    }
                </div>
            </div>
        </div>
    )
}