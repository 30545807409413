import {useStatsContext} from "../contexts/statisticsContext";
import {bordercolor} from "../theme";
import React from "react";

export default function CardDeploys(props){
    const {reports, cardTotalStats, loadingStats } = useStatsContext();
    const {hoverId, setHoverID} = props;

    function CardList(props){
        const {list, text} = props;
        return (
            <div className={"flex flex-col h-1/3 w-full lg:h-full lg:w-1/3 bg-[#183326C1] overflow-hidden rounded-xl border border-[#51b78c]"}>
                <div className={"flex flex-row h-10 w-full justify-center items-center"}>{text}</div>
                <div className={"flex flex-col h-full w-full overflow-y-auto"}>
                    <div className={"flex flex-col h-auto w-full"}>
                        {(list && list.length > 0) && list.map((card, ind) => (
                            <div key={ind} onClick={() => setHoverID(card.cardid)}
                                className={"flex flex-row bg-[#4CB190C3] h-10 w-full justify-between items-center border px-2 hover:opacity-100 opacity-70 " + bordercolor}>
                                <p>{card.cardName}</p>
                                <p>{card.amount + "x"}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={"flex flex-col lg:flex-row h-full w-full gap-3 overflow-hidden p-4 border-[#51b78c] border-t"}>
            <CardList list={cardTotalStats.unitDeployed} text={"Units"} />
            <CardList list={cardTotalStats.rcardsDeployed} text={"Mana"} />
            <CardList list={cardTotalStats.actionsDeployed} text={"Actions"} />
        </div>
    )
}