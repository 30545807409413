import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import {useAppContext} from "../contexts/appContext";

export default function MatchLogList(props) {
    const {activeReport, activeSort, setActiveSort, setActiveReport, loadingRM, reports, SortedReports, activeType, setActiveType, admin} = props;
    const {id} = useAppContext();

    function logVisible(ml){
        switch(activeType){
            case "all":
                return true;
            case "wins":
                return ml.winnerID === id;
            case "losses":
                return ml.winnerID !== id;
        }
    }

    return (
        <div className="flex flex-col h-full overflow-hidden justify-start items-center w-[20%] container border-[#51b78c] border-r mx-auto px-2 pt-8">
            <div className={"flex flex-col w-full h-auto justify-center items-center"}>
                <div className={"flex flex-row h-10 w-[80%]"}>
                    <Select
                        className={"bg-[#244939] border border-[#51b78c] text-white h-8 w-1/2"}
                        id="Identity"
                        placeholder={activeSort}
                        value={activeSort}
                        onChange={(e) => {
                            setActiveSort(e.target.value)
                        }}
                    >
                        <MenuItem value={"descTime"}>Time Descending</MenuItem>
                        <MenuItem value={"ascTime"}>Time Ascending</MenuItem>
                    </Select>
                    {!admin && <Select
                        className={"bg-[#244939] border border-[#51b78c] text-white h-8 w-1/2 "}
                        id="Identity"
                        placeholder={activeType}
                        value={activeType}
                        onChange={(e) => {
                            setActiveType(e.target.value)
                        }}
                    >
                        <MenuItem value={"all"}>All</MenuItem>
                        <MenuItem value={"wins"}>Wins</MenuItem>
                        <MenuItem value={"losses"}>Losses</MenuItem>
                    </Select>}
                </div>
                <div className={"flex flex-row h-12 w-full justify-center items-center text-xl"}>Reports</div>
                <div onClick={() => setActiveReport(null)}
                     className={"flex flex-row h-10 justify-center items-center w-[80%] border border-[#51b78c] border-opacity-40 " + (!activeReport ? "bg-[#51b78c]" : "")}>
                    All
                </div>
            </div>
            <div className="flex flex-col gap-0.5 items-center w-full py-2 text-xs overflow-y-auto">
                {!loadingRM && reports && SortedReports(reports).map((el, ind) =>
                    <div onClick={() => setActiveReport(el)} key={ind}
                         className={(logVisible(el) ? "flex": "hidden") + " flex-col h-12 justify-center items-center w-[80%] border border-[#51b78c] border-opacity-40 " + (activeReport && activeReport.id === el.id ? "bg-[#51b78c]" : "")}>
                        <p>{el.PlayerOne + " vs " + el.PlayerTwo}</p>
                        <p>{el.startTime}</p>
                    </div>)}
            </div>
        </div>
    )
}