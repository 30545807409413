import {Button, Input, TextInput} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {useAppContext} from "../contexts/appContext";
import {supabase} from "../utils/supabase";
import toast from "react-hot-toast";
import {TextField} from "@mui/material";

export default function AccountManagement(props) {
    const {profile, GetProfile, id, email} = useAppContext();
    const [tempName, setTempName] = useState(profile ? profile.username : "");
    const [fieldsComplete, setFieldsComplete] = useState(false);
    const [currentPass, setCurrentPass] = useState("");
    const [tempPass1, setTempPass1] = useState("");
    const [tempPass2, setTempPass2] = useState("");
    const [passwordsmatch, setPasswordsMatch] = useState(false);


    useEffect(() => {
        if (tempName.length < 4 || tempName === profile.username) {
            setFieldsComplete(false);
        } else {
            setFieldsComplete(true);
        }
    }, [tempName]);

    useEffect(() => {
        if (tempPass1 === tempPass2) {
            if (tempPass1.length > 7) setPasswordsMatch(true);
            else setPasswordsMatch(false);
        } else setPasswordsMatch(false);
    }, [tempPass1, tempPass2]);

    async function UpdatePassword() {
        const { data, error } = await supabase.auth.updateUser({
            password: tempPass1
        })
        if(error) {
            toast.error("Failed to change password.");
        } else {
            setCurrentPass("");
            setTempPass1("");
            setTempPass2("");
            toast.success("Password change saved successfully.");
        }
    }

    async function UpdateAccount(_name, _email) {
        if (_name !== profile.username) {
            const {error} = await supabase.from('profiles')
                .update({username: _name})
                .eq("user_id", id)
            if (error) {
                toast.error('Failed to update username in profile - ' + error.message, {
                    duration: 4000,
                    position: 'bottom-right',
                });
            } else {
                GetProfile(id, _name, _email);
                toast.success("Changes saved successfully.");
            }
        }
    }

    return (
        <div className={"flex flex-col h-full w-full overflow-hidden px-4 py-4"}>
            <div className={"flex flex-row items-end w-full h-16 justify-end pt-4 text-xl py-2 px-4"}>
                <TextInput
                    w={"100%"}
                    type="text"
                    label={"Username"}
                    value={tempName}
                    onChange={(e) => setTempName(e.target.value)}
                />
                <div className={"flex flex-row w-auto px-2 gap-2"}>
                    <Button disabled={!fieldsComplete} size={"xs"} color={"green"} onClick={() => {
                        UpdateAccount(tempName, email);
                    }}>Save</Button>
                </div>
            </div>
            <div className={"flex flex-col items-center h-full w-full justify-start mt-4 py-4 gap-2 px-4"}>
                <TextInput
                    w={"100%"}
                    onChange={(e) => {
                        setCurrentPass(e.target.value);
                    }}
                    label="Current Password"
                    value={currentPass}
                    type="password"
                />
                <TextInput
                    w={"100%"}
                    onChange={(e) => {
                        setTempPass1(e.target.value);
                    }}
                    label="New Password"
                    value={tempPass1}
                    type="password"
                />
                <TextInput
                    w={"100%"}
                    onChange={(e) => {
                        setTempPass2(e.target.value);
                    }}
                    label="Password Confirmation"
                    value={tempPass2}
                    type="password"
                />
                <div className={"h-4"}></div>
                <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    disabled={!passwordsmatch}
                    onClick={() => {
                        UpdatePassword();
                    }}
                >
                    Change Password
                </Button>
            </div>
            <div className={"flex flex-row h-[200px] w-full justify-center items-center"}>
                <Button variant={"default"}
                        className={"flex flex-row h-10 mt-6 justify-center items-center text-sm text-white w-[70%]"}
                        onClick={() => {
                            supabase.auth.signOut();
                            toast.success('Logged out.', {
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                            });
                        }}>
                    Logout
                </Button>
            </div>
        </div>
    )
}