import {easing, Slide} from "@mui/material";
import NewsFeed from "../news/newsfeed";
import {bordercolor} from "../theme";
import {useEffect} from "react";
import {useSocialContext} from "../contexts/socialContext";
import toast from "react-hot-toast";
import {supabase} from "../utils/supabase";

export default function Home(props){
    const {friendid} = props;
    const {friends, friendExists} = useSocialContext();

    async function RequestFriend(){
        const {error} = await supabase.from('friends').insert({friendid: friendid, status: "request"});
        if (error) {
            toast.error('Failed to post message.', {
                duration: 4000,
                position: 'bottom-right',
            });
        }
    }

    useEffect(()=>{
        if(friendid){
            console.log("Friend target ID = " + friendid);
            if(friendExists(friendid)){
                toast.error("The provided friend link is alreadu in your friends list.");
            } else {
                toast.success("The provided friend link id has been sent a friend request.");

            }
        }
    }, [friendid])
    return (
        <div className="flex flex-wrap h-full w-full justify-center gap-2 items-center overflow-y-auto">
            <NewsFeed />
        </div>
    )
}