import { Document, Page, StyleSheet, PDFViewer, Image  } from '@react-pdf/renderer';
import React, {useEffect, useState} from "react";
import {useDbContext} from "../contexts/dbContext";
import {ListItemText} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {bordercolor} from "../theme";

export default function CardSheet(props) {
    const {activeDeck, setActiveDeck, myDecks} = useDbContext();
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [width, setWidth] = useState("auto");
    const [leftMargin, setLeftMargin] = useState("11mm");
    const [height, setHeigth] = useState("88mm");
    const [deck, setDeck] = useState(null);

    const styles = StyleSheet.create({
        page: {
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "flex-start",
            alignContent: "center",
            marginLeft: leftMargin,
            backgroundColor: 'rgba(255,255,255,0)',
        }
    });

    useEffect(() => {
        if(activeDeck){ setDeck(activeDeck); }
    }, [activeDeck]);

    useEffect(() => {
        if(deck) {
            GetCardList();
        }
    }, [deck]);

    function GetCardList(){
        let ls = [];
        for(let i = 0; i < deck.maindeck.length; i++) {
            let nc = "/cards/" + deck.maindeck[i].Id + ".png";
            ls.push(nc);
        }
        for(let i = 0; i < deck.resourcesdeck.length; i++) {
            let nc = "/cards/" + deck.resourcesdeck[i].Id + ".png";
            for(let i2 = 0; i2 < deck.resourcesdeck[i].amount; i2++){
                ls.push(nc);
            }
        }
        for(let i = 0; i < deck.bench.length; i++) {
            let nc = "/cards/" + deck.bench[i].Id + ".png";
            ls.push(nc);
        }
        setCards(ls);
        setLoading(false);
    }

    return (
        <div className={"w-full h-full overflow-hidden"}>
            {loading ? <></> :
                <PDFViewer className={"h-full w-full"}>
                    <Document title={deck.deckName + " card sheet"} author={deck.ownerName} creator={deck.ownerName}>
                        <Page size={"A4"} style={styles.page}>
                            {cards.length > 0 && cards.map((crd, i) => (
                                <Image key={i} src={crd} style={{height: height, width: width, margin: 0, padding: 0}}
                                       alt={crd}/>
                            ))}
                        </Page>
                    </Document>
                </PDFViewer>}
                <div
                    className={"absolute flex flex-col w-[250px] h-[280px] rounded-t-2xl justify-center items-center bottom-0 right-[20px] bg-[#0a0f13] px-4 border-t border-l border-r " + bordercolor}>
                    <p>Deck:</p>
                    <Select
                        className={"bg-[#244939] border border-[#51b78c] w-full h-8 text-white"}
                        id="Decks"
                        value={deck}
                        onChange={(e) => setDeck(e.target.value)}
                    >
                        {myDecks.map((el) => (
                            <MenuItem key={el} value={el}>
                                <ListItemText className={"text-black"} primary={el.deckName}/>
                            </MenuItem>
                        ))}
                    </Select>
                    <p>Height:</p>
                    <input placeholder={"height"} value={height}
                           onChange={e => setHeigth(e.target.value)}
                           className={"h-8 bg-[#244939] border w-full border-[#51b78c] rounded-lg p-1"}/>
                    <p>Width:</p>
                    <input placeholder={"width"} value={width}
                           onChange={e => setWidth(e.target.value)}
                           className={"h-8 bg-[#244939] border w-full border-[#51b78c] rounded-lg p-1"}/>
                    <p>Margin Left:</p>
                    <input placeholder={"margin left"} value={leftMargin}
                           onChange={e => setLeftMargin(e.target.value)}
                           className={"h-8 bg-[#244939] border w-full border-[#51b78c] rounded-lg p-1"}/>
                </div>
        </div>
    )
}