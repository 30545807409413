import React, {useState} from "react";
import Button from "@mui/material/Button";
import {DeleteWidget, WinnerMedal} from "./activeLogEntry";

export default function MatchLogEntry(props) {
    const {report, ind, DeleteReport, activeReport, user} = props;
    const [expanded, setExpanded] = useState(false);
    return (
        <div key={ind}
             className="bg-[#3b6f5e26] rounded-lg gap-1 shadow-md border border-[#51b78c] p-4 mt-4 flex flex-col h-auto w-full">
            <div className="flex flex-col h-auto w-full">
                <div className={"flex flex-row h-10 w-full justify-beteen"}>
                    <h2 className="flex flex-row w-full h-auto text-[#6de9d8] font-semibold">{"Match " + report.MatchID}</h2>
                    {!user && <DeleteWidget  report={activeReport} DeleteReport={DeleteReport} activeReport={activeReport} />}
                </div>
                <p className="flex flex-row w-full text-xs h-auto text-[#6de9d8] font-semibold">{report.PlayerOne + " vs " +
                    report.PlayerTwo}</p>
            </div>
            <div
                className="flex flex-row h-auto w-full text-[#6de9d8] text-sm justify-start items-start">{"Player 1: <" +
                report.pOneID + "> " + report.PlayerOne}{report.winnerID === report.pOneID && <WinnerMedal/>}
            </div>
            <div
                className="flex flex-row h-auto w-full text-[#6de9d8] text-sm justify-start items-start">{"Player 2: <" +
                report.pTwoID + "> " + report.PlayerTwo}{report.winnerID === report.pTwoID && <WinnerMedal/>}
            </div>
            <p className="flex flex-row h-auto w-full text-[#6de9d8] text-sm justify-start items-start">{"Winner: " + report.winnerID}</p>
            <p className="flex flex-row h-auto w-full text-[#6de9d8] text-sm justify-start items-start">{"Total Turns: " + report.totalTurns}</p>
            <p className="flex flex-row h-auto w-full text-[#6de9d8] text-sm justify-end items-end">{"Started on " + report.startTime}</p>
            <div className={"flex flex-col w-full overflow-y-auto " + (expanded ? " h-[500px]" : " h-24")}>
                <div className={"flex flex-col gap-1 h-auto w-full"}>
                    {report && report.log && report.log.map((el, ind) =>
                        <div key={ind} className={"flex flex-row w-full h-auto text-xs text-white bg-slate-900"}>
                            {el}
                        </div>)}
                </div>
            </div>
            <div className="flex flex-row h-auto w-full text-[#6de9d8] text-sm justify-between items-end">
                <p></p>
                <Button onClick={() => setExpanded(!expanded)}>{expanded ? "Close" : "Expand"}</Button>
                <p>{"Ended on " + report.endTime}</p>

            </div>
        </div>
    )
}