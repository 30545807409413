
export function sendClick() {
    if (window.vuplex) {
        window.vuplex.postMessage({type: 'interaction', message: 'clicked'});
    }
}

export function sendCardsize(cardsize, cardsperpage) {
    if (window.vuplex) {
        window.vuplex.postMessage({type: 'cardsize', message: cardsize});
    }
}

export function sendCardPP(cardsperpage) {
    if (window.vuplex) {
        window.vuplex.postMessage({type: 'cardpages', message: cardsperpage});
    }
}

