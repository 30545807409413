import React, {useEffect, useState} from "react";
import DbNavBar from "./dbnavbar";
import {useAppContext} from "../contexts/appContext";
import {useDbContext} from "../contexts/dbContext";
import CardBox from "./cardbox";
import RightBar from "./rightbar";
import {
    ActionIcon,
    Input,
    SegmentedControl,
    Slider,
} from '@mantine/core';
import PublicDecks from "./publicDecks";
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import Tooltip from "@mui/material/Tooltip";
import { Pagination } from '@mantine/core';
import {dbstyle} from "./dbstylings";
import {sendCardPP, sendCardsize, sendClick} from "./vuplexinteract";
import UpdatesFeed from "./updates";
import {useLocation} from "react-router-dom";

export function DeckBuilder(props) {
    const {cardsOnly} = props;
    const {hideMenu, setHideMenu} = useAppContext();
    const {
        resources,
        pages,
        setSearchText,
        TotalCards,
        InDeck,
        SetInDeck,
        units,
        actions,
        cardSize,
        setCardSize,
        cardsPerPage,
        setCardsPerPage,
        currentPageLabel,
        setCurrentPageLabel,
        deckWindow,
        setDeckWindow,
        decksLoading,
        showOwn,
        setShowOwn,
        hideAdded,
        setHideAdded,
        hideIncomplete,
        setHideIncomplete,
        decksPerPage,
        setDecksPerPage ,
        currentDeckPage,
        setCurrentDeckPage,
        currentDeckPageLabel,
        setCurrentDeckPageLabel,
        deckPages,
        setDeckPages,
        decks
    } = useDbContext();

    //#region States
    const { hoverId, setHoverID, setHoverType} = props;
    const [excludeAnyDeck, setExcludeAnyDeck] = useState(false);
    const [decklistOpen, setDeckListOpen] = useState(false);
    const [openTab, setOpenTab] = useState("DeckList");
    const [openADTab, setOpenADTab] = useState("info");
    const [dsOpen, setDsOpen] = useState(false);
    const [dpOpen, setDpOpen] = useState(false);
    const [st, setSt] = useState("");
    //#endregion
    let route = useLocation();

    const ingame = route.pathname.split("/")[1] === "ingame";

    useEffect(() => {
        setHideMenu(cardsOnly);
    },[cardsOnly])

    function startsearch() {
        setSearchText(st);
    }

    function DeckFilters(){
        return (
            <div className={"flex flex-row w-full justify-end gap-2 pr-2 items-center h-full overflow-hidden"}>
                <Slider
                    style={{ width: "20%" }}
                    min={1}
                    max={decks.length}
                    step={1}
                    value={decksPerPage}
                    onChange={setDecksPerPage}
                    onChangeEnd={() => {
                        localStorage.setItem("decksPerPage", decksPerPage);
                    }}>
                </Slider>
                <Input
                    style={{ width: 100 }}
                    value={decksPerPage}
                    onChange={(e) => {
                        if (isNaN(parseInt(e.currentTarget.value))) {
                            setDecksPerPage(1);
                            localStorage.setItem("decksPerPage", "1");
                        } else {
                            setDecksPerPage(parseInt(e.currentTarget.value));
                            localStorage.setItem("decksPerPage", e.currentTarget.value);
                        }
                    }}
                />
            </div>
        )
    }

    if(decksLoading){
        <>Loading Decks..</>
    } else
    return (
        <>
            {decksLoading ?
                <>Loading Decks..</> :
                <div className={"flex flex-col h-full w-full overflow-y-hidden"}>
                    <DbNavBar ingame={ingame} startsearch={startsearch} dsOpen={dsOpen} setDsOpen={setDsOpen} setSt={setSt} st={st}
                              cardsOnly={cardsOnly}
                              dpOpen={dpOpen} setDpOpen={setDpOpen}/>

                    {dpOpen ?
                        <div className={"flex flex-row w-full h-full overflow-y-hidden"}>
                            <div className={"flex flex-col w-full h-full overflow-y-hidden"}>
                                {!cardsOnly && deckWindow ? <PublicDecks setHoverType={setHoverType} fullpage={false} setHoverID={setHoverID} hoverId={hoverId} hideMenu={hideMenu}/>:
                                    <CardBox cardsOnly={cardsOnly}/>}
                            </div>
                            {!cardsOnly &&  <RightBar setHoverType={setHoverType} setHoverID={setHoverID}/>}
                        </div>
                        :
                        <div className={"flex flex-row w-full h-full overflow-y-hidden"}>

                            {!cardsOnly && deckWindow ? <PublicDecks setHoverType={setHoverType} fullpage={false} setHoverID={setHoverID} hoverId={hoverId} hideMenu={hideMenu}/>:
                            <CardBox cardsOnly={cardsOnly}/>}
                        </div>}
                    <div className={"flex flex-row w-full h-14 justify-center items-center px-6 gap-1 border-t overflow-y-hidden " + dbstyle.headercolors}>
                            <div className={"flex flex-row justify-between items-center w-1/3 h-full text-sm"}>
                            <div className={"flex flex-row w-auto h-full justify-center items-center gap-2"}>
                               <UpdatesFeed />
                                {!cardsOnly && <Tooltip title={"Open public decks"} placement="top">
                                    <ActionIcon size={35} variant="default" aria-label="Sort menu" onClick={() => { setDeckWindow(!deckWindow);}}>
                                        <ViewQuiltIcon />
                                    </ActionIcon>
                                </Tooltip>}
                            </div>
                            {!deckWindow && !cardsOnly && <div className={"flex flex-row w-auto h-full justify-center items-center"}>
                                <SegmentedControl
                                    value={InDeck}
                                    onChange={SetInDeck}
                                    data={[
                                        { label: 'All', value: 'all' },
                                        { label: 'In Deck', value: 'deck' },
                                        { label: 'Not In Deck', value: 'notindeck' },
                                    ]}
                                />
                            </div> }
                                {!deckWindow && <p className={"flex flex-row w-[150px] h-full justify-center items-center"}>{TotalCards() + " cards"}</p>}
                        </div>
                        <div className={"flex flex-row h-full w-1/3  gap-1 items-center justify-center text-white"}>
                            {!deckWindow ? <Pagination withEdges total={pages.length} value={currentPageLabel} onChange={(e) => {
                                setCurrentPageLabel(e);
                                sendClick();
                            }} /> :
                                <Pagination withEdges total={deckPages.length} value={currentDeckPageLabel} onChange={(e) => {
                                    setCurrentDeckPageLabel(e);
                                    sendClick();
                                }} />
                            }
                        </div>
                        <div className={"flex flex-row w-1/3  h-full justify-start items-center text-sm"}>
                            {deckWindow ? <DeckFilters /> :
                            <div className={"flex flex-row w-full h-full gap-2 justify-center text-xs items-center"}>
                                <div className={"flex flex-row items-center justify-center text-xs gap-1 h-14 w-1/2"}>
                                    <p className={"flex flex-row  w-[100px] justify-center items-center"}>Card Size</p>
                                    <Slider
                                        style={{ width: "100%" }}
                                        min={0.00}
                                        max={1.00}
                                        step={0.01}
                                        defaultValue={cardSize}
                                        value={cardSize}
                                        onChange={setCardSize}
                                        onChangeEnd={() => {
                                            localStorage.setItem("cardsize", cardSize);
                                            sendCardsize(cardSize);
                                        }}>
                                    </Slider>
                                    <Input style={{ width: 100 }}
                                           value={cardSize}
                                           onChange={(e) => {
                                               if (isNaN(parseFloat(e.currentTarget.value))) {
                                                   setCardSize(0.01);
                                                   localStorage.setItem("cardsize", "0.01");
                                                   sendCardsize(0.01);
                                               } else {
                                                   setCardSize(parseFloat(e.currentTarget.value));
                                                   localStorage.setItem("cardsize", e.currentTarget.value);
                                                   sendCardsize(cardSize);
                                               }
                                           }}
                                    />
                                </div>
                                <div className={"flex flex-row items-center justify-center h-14 gap-1 w-1/2"}>
                                    <p className={"flex flex-row w-[100px] justify-center items-center"}>Cards</p>
                                    <Slider
                                        style={{ width: "100%" }}
                                        min={1}
                                        max={units.length + actions.length + resources.length}
                                        step={1}
                                        defaultValue={cardsPerPage}
                                        value={cardsPerPage}
                                        onChange={setCardsPerPage}
                                        onChangeEnd={() => {
                                            localStorage.setItem("cardspage", cardsPerPage);
                                            sendCardPP(cardsPerPage)
                                        }}>
                                    </Slider>
                                    <Input
                                        style={{ width: 100 }}
                                        value={cardsPerPage}
                                        onChange={(e) => {
                                            if (isNaN(parseInt(e.currentTarget.value))) {
                                                setCardsPerPage(1);
                                                localStorage.setItem("cardspage", "1");
                                                sendCardPP(1)
                                            } else {
                                                setCardsPerPage(parseInt(e.currentTarget.value));
                                                localStorage.setItem("cardspage", e.currentTarget.value);
                                                sendCardPP(parseInt(e.currentTarget.value))
                                            }
                                        }}
                                    />
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>}
        </>
    )
}

const sdeckstyle = "flex flex-col h-full w-[500px] items-center justify-start";