import SendIcon from "@mui/icons-material/Send";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import React, {useEffect, useRef, useState} from "react";
import toast from "react-hot-toast";
import {supabase} from "../utils/supabase";
import {useSocialContext} from "../contexts/socialContext";
import {useAppContext} from "../contexts/appContext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Blockquote, Input, ScrollArea} from "@mantine/core";

export default function ChannelView(props) {
    const {sbOpen} = props;
    const [openChannel, setOpenChannel] = useState("lobby");
    const [notBottom, setNotBottom] = useState(false);
    const [scrollPos, setScrollPos] = useState(0);
    const [responseText, setResponseText] = useState("");
    const {profile, id, isAdmin} = useAppContext();
    const {lobbyMessages, loadingMessages, users, setChannelRead, friendExists, UserData} = useSocialContext();
    const [userList, setUserList] = useState([]);
    const viewport = useRef(null);

    async function SendMessageChat() {
        let msg = {displayname: profile.username, sender: id, message: responseText, admin: isAdmin};
        const {error} = await supabase.from('lobbychat').insert(msg);
        if (error) {
            toast.error('Failed to post message.', {
                duration: 4000,
                position: 'bottom-right',
            });
        }
        setResponseText("");
    }

    useEffect(() => {
        parseUserList();
    }, [users]);

    function parseUserList(){
        let ul = [];
        let tlist = [];
        if(!users) return ul;
        for(let i = 0; i < users.length; i++) {
            if(!tlist.includes(users[i].id)) {
                ul.push(users[i]);
                tlist.push(users[i].id);
            }
        }
        setUserList(ul);
    }

    const scrollToBottom = () => {
        viewport.current.scrollTo({ top: viewport.current.scrollHeight, behavior: 'smooth' });
    }

    useEffect(() => {
        scrollToBottom();
    }, [lobbyMessages]);

    useEffect(() => {
        if(!loadingMessages){
            setChannelRead();
        }
    }, [loadingMessages])

    return (

        <div className={"flex flex-row items-center justify-start h-full text-sm lg:text-md w-full overflow-hidden"}>
            {sbOpen &&
                <div className={"flex flex-col w-1/4 h-full justify-start items-center border-r border-[#51b78c]"}>
                    <div
                        className={"flex flex-row w-full h-12 justify-center items-center bg-black bg-opacity-30 border-b border-[#51b78c]"}>
                        Online Users
                    </div>
                    <div className={"flex flex-col w-full h-full overflow-y-auto"}>
                    <div className={"flex flex-col w-full h-auto justify-start items-center"}>
                        {userList.map(user => (
                            <UserEntry key={user.id} user={user} />
                            ))}
                    </div>
                </div>
            </div>}
            <div className={"flex flex-col h-full " + (sbOpen ? " w-3/4" : " w-full")}>
                <div className={"flex flex-row h-full pt-2 pl-2 overflow-hidden w-full"}>
                    <div id="messagecontainer" onScroll={(e) => {
                        setScrollPos(e.target.scrollTop);
                    }} className={"flex flex-col items-start justify-start h-full w-full overflow-hidden overflow-x-hidden rounded-t-xl gap-2"}>
                        <ScrollArea color="gray" offsetScrollbars  p={"md"} w={"100%"} viewportRef={viewport}>
                            <div className={"flex flex-col w-full h-auto justify-end items-end"}>
                                {lobbyMessages && lobbyMessages.map((message, ind) => (
                                    <Blockquote key={ind} mt="md" w={message.sender === id ? "90%" : "100%"} color={message.sender === id ? "teal" : "indigo"} radius="xl" cite={<div className={"flex flex-row justify-between w-full lg:text-md font-bold h-auto"}>
                                        <p>{message.admin && <AdminPanelSettingsIcon className={"mr-3"}/>}{message.displayname}</p>
                                        <p className={"flex flex-row items-center w-auto justify-end"}>Sent
                                            at {new Date(message.created_at).toUTCString()}</p>
                                    </div>}>
                                            {message.message}
                                    </Blockquote>
                                ))}
                            </div>
                        </ScrollArea>
                            <div id={"messagesend"} className={"h-1 w-1"}></div>
                    </div>
                </div>
                <div className={"flex flex-col items-start justify-start h-20 w-full pl-2 pb-2 overflow-hidden"}>
                    <div className={"flex flex-col w-full h-full bg-base-100 rounded-b-xl py-1 pr-4 pl-3"}>
                        <div className={"flex w-full h-full items-center justify-center gap-2 flex-row py-2"}>
                            <Input w={"100%"} value={responseText} onKeyDown={(e) => {
                                if(e.key === "Enter") {
                                    setResponseText(e.currentTarget.value);
                                    SendMessageChat();
                                }
                            }} onChange={(e) => {
                                setResponseText(e.currentTarget.value)
                            }}></Input>
                            <div
                                className={"btn btn-secondary btn-md flex flex-row w-auto h-auto justify-center items-center tooltip tooltip-left"}
                                onClick={() => {
                                    SendMessageChat();
                                }} data-tip={"Send Message"}>
                                <SendIcon size={"small"}/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function UserEntry(props) {
    const {user} = props;
    const {profile, id, isAdmin} = useAppContext();
    const {friendExists, UserData, friends} = useSocialContext();
    const [contextMenu, setContextMenu] = useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        if(user.id !== id && !friendExists(user.id, friends)) {
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                      // Other native context menus might behave different.
                      // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                    null,
            );
        }
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    const handleAdd = async(friendid) => {
        setContextMenu(null);
        if(user.id === id || friendExists(friendid)) return;

        const {error} = await supabase.from('friends').insert({
            friendid: friendid,
            status: "request"
        });
        if(error){
            toast.error('Failed to send friend request.', {
                duration: 4000,
                position: 'bottom-right',
            });
            return;
        }
        toast.success('Friend request to ' + UserData(friendid).username + ' successfully sent.', {
            duration: 4000,
            position: 'bottom-right',
        });
    };
    return (
        <div key={user.id} onContextMenu={handleContextMenu} onClick={handleContextMenu}
             className={"flex flex-row h-10 w-full justify-center items-center " + (user.id === id ? "text-[#306a4e]" : "hover:bg-slate-400 hover:bg-opacity-10 text-[#51b78c]")}>
            {user.user}
            <Menu open={contextMenu !== null}
                  onClose={handleClose}
                  anchorReference="anchorPosition"
                  anchorPosition={
                      contextMenu !== null
                          ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
                          : undefined
                  }
            >
                <MenuItem onClick={() => handleAdd(user.id)}>Add Friend</MenuItem>
            </Menu>
        </div>
    )
}