import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'react-tooltip/dist/react-tooltip.css'
import { theme } from "./theme";
import {AppContextProvider} from "./contexts/appContext";
import {DbContextProvider} from "./contexts/dbContext";
import {ThemeProvider} from "@mui/material";
import {BrowserRouter} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import {NewsContextProvider} from "./contexts/newsContext";
import {SocialContextProvider} from "./contexts/socialContext";
import '@mantine/core/styles.css';

import { createTheme, MantineProvider } from '@mantine/core';

const myColor = [
    '#f4f4f6',
    '#e6e6e6',
    '#cacacb',
    '#acadb1',
    '#93949b',
    '#82848e',
    '#7a7c89',
    '#686a77',
    '#5c5e6b',
    '#4d5160'
];

const thememantis = createTheme({
    colors: {
        myColor,
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <MantineProvider theme={thememantis} defaultColorScheme="dark">
            <BrowserRouter>
                <AppContextProvider>
                    <DbContextProvider>
                        <NewsContextProvider >
                            <SocialContextProvider>
                                <App />
                                <Toaster />
                            </SocialContextProvider>
                        </NewsContextProvider>
                    </DbContextProvider>
                </AppContextProvider>
            </BrowserRouter>
        </MantineProvider>
    </ThemeProvider>
);
