import {useEffect, useState} from "react";
import {supabase} from "../utils/supabase";

export default function Profile(props) {
    const {playerid} = props;
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        if(playerid && playerid !== ""){
            GetProfile(playerid);
        }
    }, [playerid])

    async function GetProfile(_playerid){
        const { data, error } = await supabase
            .from('profiles')
            .select()
            .eq("user_id", _playerid);
        if(error){
            setProfile(null);
            setLoading(false);
        } else {
            if (data.length > 0) {
                setProfile(data[0]);
                setLoading(false);
            }
        }
    }

    return (
        <div className="flex flex-col h-full w-full overflow-hidden">
            {loading && !profile ? <div className="flex flex-col h-full w-full justify-center items-center overflow-hidden">
                    Loading..
                </div> :
                <div className={"flex flex-row h-20 w-full justify-center items-center"}>
                    <p className={"flex flex-row w-8 h-8 justify-center items-center rounded-full bg-green-800 mr-4"}>{profile.level}</p>
                    {profile.username}
                </div>}
        </div>
    )
}