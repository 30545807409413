import React, {useEffect, useRef, useState} from "react";
import {useDbContext} from "../contexts/dbContext";
import {useAppContext} from "../contexts/appContext";
import ArrowCircleUpTwoToneIcon from '@mui/icons-material/ArrowCircleUpTwoTone';
import ArrowCircleDownTwoToneIcon from '@mui/icons-material/ArrowCircleDownTwoTone';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import {dbstyle} from "./dbstylings";
import Tooltip from "@mui/material/Tooltip";
import {ActionIcon, Button, Menu, Modal, rem, ScrollArea, Switch} from "@mantine/core";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import {Card} from "./cardentries";
import {useDisclosure} from "@mantine/hooks";
import {bordercolor} from "../theme";
import { Popover, Text } from '@mantine/core';

const colors = "bg-[#091C1EFF] border-[#5c5c5c]"

export default function PublicDecks(props){
    const viewportRef = useRef(null);
    const {hideMenu, hoverId, setHoverID, fullpage, setHoverType} = props;
    const {deckPages, showOwn, hideAdded, hideIncomplete, currentDeckPage, setCurrentDeckPage, currentDeckPageLabel} = useDbContext();
    const col = "#091c1e";

    useEffect(() => {
        setCurrentDeckPage(currentDeckPageLabel -1);
    }, [currentDeckPageLabel])

    return (
        <div className={colors +" h-full w-full flex flex-col left-[10px] overflow-hidden "}>
            <div className={"flex relative flex-col h-full w-full overflow-hidden"}>
                <div className={"flex flex-row h-full w-full gap-1 justify-around items-center overflow-hidden"}>
                    <div className={"flex flex-col w-full h-full gap-1  overflow-hidden pb-2"}>
                        <div className={"flex flex-row h-12 w-full bg-[#3b6f5e26] overflow-hidden"}>
                            <div className={"flex flex-row h-full items-center justify-center w-12"}></div>
                            <div className={"flex flex-row h-full items-center justify-center w-1/6"}>Rating</div>
                                <div className={"flex flex-row h-full items-center justify-center w-3/6"}>Deck Name
                                </div>
                                <div className={"flex flex-row h-full items-center justify-center w-1/6"}>Creator</div>
                                <div className={"flex flex-row h-full items-center justify-center w-1/6"}>
                                </div>
                            </div>
                            <div className={"flex flex-col w-full h-full overflow-hidden"}>
                                <ScrollArea w={"100%"} h={"100%"} viewportRef={viewportRef}>
                                    {deckPages.length > 0 && deckPages.map((el, ind) =>
                                        <div key={ind} className={(currentDeckPage === ind ? "flex relative " : "hidden ") + " flex-wrap h-auto w-full gap-1.5 my-auto justify-center items-center py-2"}>
                                            {el.decks.map((el2, ind2) =>
                                                <Deck hideAdded={hideAdded} showOwn={showOwn} hideIncomplete={hideIncomplete} setHoverType={setHoverType} setHoverID={setHoverID} hoverId={hoverId} key={ind2} deckentry={el2}/>
                                            )}
                                        </div>
                                    )}
                                </ScrollArea>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

const col = "#1d232b"

function Deck(props) {
    const {cardsOnly, session, cardsdate, cardX, cardY} = useAppContext();
    const {deckentry} = props;
    const {addDeck, calcRating, upvote, downvote, HasDeck, OwnDeck} = useDbContext();
    const {id} = useAppContext();
    const [manaOpen, setManaOpen] = useState(false);
    const [opened, { open, close }] = useDisclosure(false);
    const [hoverId, setHoverID] = useState("");

    return (
        <div className={"flex  flex-col w-full select-none h-10 bg-[#2c3740]"}>
            <div onClick={open}
                 className={"flex flex-row h-10 w-full cursor-pointer " + (opened ? "bg-black bg-opacity-30 hover:bg-opacity-50" : "hover:bg-black hover:bg-opacity-30")}>

                <div className={"flex flex-col w-12 h-full justify-center items-center"}>
                    {opened ? <ArrowDropUpOutlinedIcon/> : <ArrowDropDownOutlinedIcon/>}
                </div>
                <div className={"flex flex-row w-1/6 justify-center items-center h-full"}>
                    <div style={{display: !opened ? "flex" : "none"}}
                         className={"font-semibold " + (calcRating(deckentry) < 0 ? " text-red-300" : " text-green-300")}>{calcRating(deckentry)}</div>
                </div>
                <div className={"flex flex-row h-full items-center justify-center w-3/6"}>{deckentry.deckName}</div>
                <div
                    className={"flex flex-row h-full items-center justify-center w-1/6"}>{deckentry.ownerName}</div>
                <div className={"flex flex-row h-full items-center justify-center w-1/6"}>

                </div>
            </div>

            <Modal size={"auto"} opened={opened} onClose={close} title={"Deck Preview: " + deckentry.deckName}>
                <div className={"flex flex-col h-full w-full overflow-hidden"}>
                    <div className={"flex flex-row h-10 w-full overflow-hidden"}>
                        <div className={"flex flex-col w-12 h-full justify-center items-center"}>

                        </div>
                        <div className={"flex flex-row w-1/6 justify-center items-center h-full"}>
                            <div onClick={() => {
                                upvote(deckentry);
                            }}
                                 style={{display: (deckentry.upvotes.includes(id) || deckentry.downvotes.includes(id) ? "none" : "flex")}}
                                 className={"flex flex-row h-10 w-10 justify-center items-center"}
                            >
                                <ArrowCircleUpTwoToneIcon/>
                            </div>
                            <div
                                className={"flex flex-row h-10 w-10 justify-center items-center font-semibold " + (calcRating(deckentry) < 0 ? " text-red-300" : " text-green-300")}>{calcRating(deckentry)}</div>
                            <div onClick={() => {
                                downvote(deckentry);
                            }}
                                 style={{display: (deckentry.upvotes.includes(id) || deckentry.downvotes.includes(id) ? "none" : "flex")}}
                                 className={"flex flex-row h-10 w-10 justify-center items-center"}>
                                <ArrowCircleDownTwoToneIcon/>
                            </div>
                        </div>
                        <div
                            className={"flex flex-row h-full items-center justify-center w-3/6"}>{deckentry.deckName}</div>
                        <div className={"flex flex-row h-full items-center justify-center w-1/6"}>

                        </div>
                        <div
                            className={"flex flex-row h-full items-center justify-center w-1/6"}> {!HasDeck(deckentry.id) ?
                            <Tooltip title={"Add to my decks"} placement="top">
                                <Button variant="default" onClick={() => {
                                    const clone = (({id, created_at, ...o}) => o)(deckentry);
                                    clone.origin = deckentry.id;
                                    addDeck(clone);
                                }}>
                                    Add to My Decks
                                </Button>
                            </Tooltip> : <div>
                                Already added
                            </div>}</div>
                    </div>
                    <div className={"flex flex-row h-14 w-full justify-center items-center overflow-hidden"}>
                        <div className={"flex flex-col w-12 h-full justify-center items-center"}>

                        </div>
                        <div className={"flex flex-row h-10 w-4/6 justify-center items-center"}>
                            {deckentry.description}
                        </div>
                        <div className={"flex flex-row h-10 w-1/6 justify-center items-center"}>
                            {"Creator: " + deckentry.ownerName}
                        </div>
                    </div>
                    <div className={"flex flex-row h-10 w-3/6 flex-wrap justify-center items-center overflow-hidden"}>

                    </div>
                    <div className={"flex flex-row h-10 w-full justify-around items-center  overflow-hidden"}>
                        <div className={"py-2"}>Cards in <Button variant="default" onClick={() => {
                            setManaOpen(false)
                        }}>Main</Button>|<Button variant="default" onClick={() => {
                            setManaOpen(true)
                        }}>Mana</Button> Deck
                        </div>
                    </div>
                    {manaOpen ?
                        <div className={"flex flex-col h-full items-center overflow-hidden justify-center w-full"}>
                            <div
                                className={"flex flex-wrap w-full h-full justify-center items-center overflow-y-auto p-2 gap-2"}>
                                {deckentry.resourcesdeck.map((card, ind) => (

                                    <Popover key={ind} width={430} position="right" opened={hoverId === card.Id}
                                             withArrow shadow="md">
                                        <Popover.Target>
                                            <div
                                                className={"flex flex-row rounded-lg justify-end items-end " + dbstyle.cards.notselected}
                                                decoding="async"
                                                style={{
                                                    cursor: 'context-menu',
                                                    height: cardY * 0.25,
                                                    width: cardX * 0.25,
                                                    backgroundSize: "contain",
                                                    backgroundImage: "url(cards/" + card.Id + ".png?key=" + cardsdate + ")"
                                                }} onMouseEnter={() => {
                                                setHoverID(card.Id);
                                            }} onMouseLeave={() => {
                                                setHoverID("");
                                            }}>
                                                <p className={"flex w-full h-10 justify-end items-end text-2xl shadow-black shadow text-green-300 p-1"}>{"x" + card.amount}</p>
                                            </div>
                                        </Popover.Target>
                                        <Popover.Dropdown style={{pointerEvents: 'none'}}>
                                            <div
                                                className={"flex flex-col min-h-40 h-auto w-[400px] z-50 p-3 overflow-hidden self-center justify-self-center bg-[#0a0f13] rounded-2xl border " + bordercolor}>
                                                <img className={"h-auto w-full rounded-2xl"} alt={"preview" + hoverId}
                                                     src={"/cards/" + hoverId + ".png"}/>
                                            </div>
                                        </Popover.Dropdown>
                                    </Popover>
                                ))}
                            </div>
                        </div>
                        :
                        <div className={"flex flex-col h-full items-center overflow-hidden justify-center w-full"}>
                            <div
                                className={"flex flex-wrap w-full h-full justify-center items-center overflow-y-auto p-2 gap-2"}>
                                {deckentry.maindeck.map((card, ind) => (
                                    <Popover key={ind} width={430} position="right" opened={hoverId === card.Id}
                                             withArrow shadow="md">
                                        <Popover.Target>
                                            <div className={"flex flex-row rounded-lg " + dbstyle.cards.notselected}
                                                 style={{
                                                     cursor: 'context-menu',
                                                     height: cardY * 0.25,
                                                     width: cardX * 0.25,
                                                     backgroundSize: "contain",
                                                     backgroundImage: "url(cards/" + card.Id + ".png?key=" + cardsdate + ")"
                                                 }} onMouseEnter={() => {
                                                setHoverID(card.Id);
                                            }} onMouseLeave={() => {
                                                setHoverID("");
                                            }}></div>
                                        </Popover.Target>
                                        <Popover.Dropdown style={{pointerEvents: 'none'}}>
                                            <div
                                                className={"flex flex-col min-h-40 h-auto w-[400px] z-50 p-3 overflow-hidden self-center justify-self-center bg-[#0a0f13] rounded-2xl border " + bordercolor}>
                                                <img className={"h-auto w-full rounded-2xl"} alt={"preview" + hoverId}
                                                     src={"/cards/" + hoverId + ".png"}/>
                                            </div>
                                        </Popover.Dropdown>
                                    </Popover>
                                ))}
                            </div>
                        </div>}
                </div>
            </Modal>

            {/* infoOpen &&
                <div className={"flex flex-col h-full w-full overflow-hidden"}>
                    <div className={"flex flex-row h-10 w-full justify-center items-center"}>
                        <div className={"flex flex-row h-full items-center justify-center w-12"}></div>
                        <div className={"flex flex-row h-10 w-1/6 justify-center items-center"}>
                            <div onClick={() => {
                                upvote(deckentry);
                            }}
                                 style={{display: (deckentry.upvotes.includes(id) || deckentry.downvotes.includes(id) ? "none" : "flex")}}
                                 className={"flex flex-row h-10 w-10 justify-center items-center"}
                            >
                                <ArrowCircleUpTwoToneIcon/>
                            </div>
                            <div
                                className={"flex flex-row h-10 w-10 justify-center items-center font-semibold " + (calcRating(deckentry) < 0 ? " text-red-300" : " text-green-300")}>{calcRating(deckentry)}</div>
                            <div onClick={() => {
                                downvote(deckentry);
                            }}
                                 style={{display: (deckentry.upvotes.includes(id) || deckentry.downvotes.includes(id) ? "none" : "flex")}}
                                 className={"flex flex-row h-10 w-10 justify-center items-center"}>
                                <ArrowCircleDownTwoToneIcon/>
                            </div>
                        </div>
                        <div className={"flex flex-row h-10 w-4/6 justify-center items-center"}>
                        </div>
                        <div className={"flex flex-row h-10 w-1/6 justify-center items-center"}>
                            {!HasDeck(deckentry.id) ? <Tooltip title={"Add to my decks"} placement="top">
                                <Button variant="default" onClick={() => {
                                    const clone = (({id, created_at, ...o}) => o)(deckentry);
                                    clone.origin = deckentry.id;
                                    addDeck(clone);
                                }}>
                                    Add to My Decks
                                </Button>
                            </Tooltip> : <div>
                                Already added
                            </div>}
                        </div>
                    </div>
                    <div className={"flex flex-row h-10 w-3/6 flex-wrap justify-center items-center"}>
                        {deckentry.description}
                    </div>
                    <div className={"flex flex-row h-10 w-full justify-around items-center"}>
                        <div className={"py-2"}>Cards in <Button variant="default" onClick={() => {
                            setManaOpen(false)
                        }}>Main</Button>|<Button variant="default" onClick={() => {
                            setManaOpen(true)
                        }}>Mana</Button> Deck
                        </div>
                    </div>
                    {manaOpen ?
                        <div className={"flex flex-col h-full items-center overflow-hidden justify-center w-full"}>
                            <div
                                className={"flex flex-wrap w-full h-full justify-center items-center overflow-y-auto p-2 gap-2"}>
                                {deckentry.resourcesdeck.map((card, ind) => (
                                    <div key={ind} className={"flex flex-row rounded-lg justify-end items-end " + dbstyle.cards.notselected}
                                         decoding="async"
                                         style={{
                                             cursor: 'context-menu',
                                             height: 1040 * 0.2,
                                             width: 744 * 0.2,
                                             backgroundSize: "contain",
                                             backgroundImage: "url(cards/" + card.Id + ".png?key=" + cardsdate + ")"
                                         }} onMouseEnter={() => {
                                        setHoverID(card.Id);
                                        setHoverType("decks");
                                    }} onMouseLeave={() => {
                                        setHoverType("");
                                        setHoverID("");
                                    }}>
                                        <p className={"flex w-full h-10 justify-end items-end text-2xl shadow-black shadow text-green-300 p-1"}>{"x" + card.amount}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        :
                        <div className={"flex flex-col h-full items-center overflow-hidden justify-center w-full"}>
                            <div
                                className={"flex flex-wrap w-full h-full justify-center items-center overflow-y-auto p-2 gap-2"}>
                                {deckentry.maindeck.map((card, ind) => (
                                    <div key={ind} className={"flex flex-row rounded-lg " + dbstyle.cards.notselected}
                                         style={{
                                             cursor: 'context-menu',
                                             height: 1040 * 0.2,
                                             width: 744 * 0.2,
                                             backgroundSize: "contain",
                                             backgroundImage: "url(cards/" + card.Id + ".png?key=" + cardsdate + ")"
                                         }} onMouseEnter={() => {
                                        setHoverType("decks");
                                        setHoverID(card.Id);
                                    }} onMouseLeave={() => {
                                        setHoverID("");
                                        setHoverType("");
                                    }}></div>
                                ))}
                            </div>
                        </div>}
                </div> */}
        </div>
    )
}

const sdeckstyle = "flex flex-col h-full w-[500px] bg-header items-center justify-start rounded-2xl px-4";
const sdeckbutton = "flex h-14 w-full justify-center items-center bg-secondary";

function NewPlayerWindow() {
    const hideMenu = true;
    return (
        <div
            className={"absolute flex flex-col lg:px-6 h-[80%] lg:h-[80%] w-full lg:w-[70%] lg:left-[15%] bg-footer rounded-2xl overflow-hidden " + (hideMenu ? "top-[9%]" : "top-[15%]")}>
            <div className={"flex flex-row h-14 w-full justify-center items-center text-primary"}>
                <p>Welcome new player discover some starter decks below.</p>
            </div>
            <div className={"flex flex-row h-full w-full overflow-y-hidden overflow-x-auto pb-8"}>
                <div className={"flex flex-row h-full w-auto gap-2 justify-center items-center"}>
                    <div className={sdeckstyle}>
                        <p className={"flex flex-row h-14 w-full text-center justify-center items-center text-lg font-bold"}>Starter
                            Deck A</p>
                        <div className={"flex flex-row w-full h-[70%] justify-center items-center"}>
                            <div
                                className={"flex flex-col w-20 h-full justify-between items-center pt-4 gap-2 text-xs"}>
                                <div
                                    className={"flex flex-col w-20 h-auto justify-start items-center pt-4 gap-2 text-xs"}>
                                    <div className={sdeckbutton}>Units(0)</div>
                                    <div className={sdeckbutton}>Actions(0)</div>
                                    <div className={sdeckbutton}>Mana(0)</div>
                                </div>

                                <div className={sdeckbutton + " justify-self-end self-end"}>
                                    Add Deck
                                </div>
                            </div>

                            <div className={"h-full w-full overflow-x-hidden overflow-y-auto"}>
                                <div className={"flex flex-wrap justify-center items-center w-full h-auto gap-2"}>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <p className={"flex flex-wrap h-auto min-h-20 w-full text-center justify-center items-center"}>
                            This is a starter deck description sample
                        </p>
                    </div>

                    <div className={sdeckstyle}>
                        <p className={"flex flex-row h-14 w-full text-center justify-center items-center text-lg font-bold"}>Starter
                            Deck A</p>
                        <div className={"flex flex-row w-full h-[70%] justify-center items-center"}>
                            <div
                                className={"flex flex-col w-20 h-full justify-between items-center pt-4 gap-2 text-xs"}>
                                <div
                                    className={"flex flex-col w-20 h-auto justify-start items-center pt-4 gap-2 text-xs"}>
                                    <div className={sdeckbutton}>Units(0)</div>
                                    <div className={sdeckbutton}>Actions(0)</div>
                                    <div className={sdeckbutton}>Mana(0)</div>
                                </div>

                                <div className={sdeckbutton + " justify-self-end self-end"}>
                                    Add Deck
                                </div>
                            </div>

                            <div className={"h-full w-full overflow-x-hidden overflow-y-auto"}>
                                <div className={"flex flex-wrap justify-center items-center w-full h-auto gap-2"}>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <p className={"flex flex-wrap h-auto min-h-20 w-full text-center justify-center items-center"}>
                            This is a starter deck description sample
                        </p>
                    </div>

                    <div className={sdeckstyle}>
                        <p className={"flex flex-row h-14 w-full text-center justify-center items-center text-lg font-bold"}>Starter
                            Deck A</p>
                        <div className={"flex flex-row w-full h-[70%] justify-center items-center"}>
                            <div
                                className={"flex flex-col w-20 h-full justify-between items-center pt-4 gap-2 text-xs"}>
                                <div
                                    className={"flex flex-col w-20 h-auto justify-start items-center pt-4 gap-2 text-xs"}>
                                    <div className={sdeckbutton}>Units(0)</div>
                                    <div className={sdeckbutton}>Actions(0)</div>
                                    <div className={sdeckbutton}>Mana(0)</div>
                                </div>

                                <div className={sdeckbutton + " justify-self-end self-end"}>
                                    Add Deck
                                </div>
                            </div>

                            <div className={"h-full w-full overflow-x-hidden overflow-y-auto"}>
                                <div className={"flex flex-wrap justify-center items-center w-full h-auto gap-2"}>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>
                                    <div className={"flex h-[240px] w-[160px] bg-primary rounded-2xl"}>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <p className={"flex flex-wrap h-auto min-h-20 w-full text-center justify-center items-center"}>
                            This is a starter deck description sample
                        </p>
                    </div>
                </div>
            </div>
            <div className={"hidden lg:flex flex-row h-14 w-full"}>

            </div>
        </div>
    )
}