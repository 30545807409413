import {supabase} from "../utils/supabase";
import { createContext, useContext, useEffect, useRef, useState } from "react";

const NewsContext = createContext({});

const NewsContextProvider = ({ children }) => {
    const [feedarticles, setFeedarticles] = useState([]);
    const [loadingNews, setLoadingNews] = useState(false);
    const [editEnabled, setEditEnabled] = useState(false);
    const webhookURL = 'https://discord.com/api/webhooks/1283535035277770752/y1ovadCbWxrAJvflyxzXtDloTcIHtKinxBXaVeGwoq3hTZrz_cwAwS4PTSNdtgSEHcoo';


    useEffect(() => {
        GetFeed();
        const channel = supabase
            .channel('newsfeed')
            .on(
                'postgres_changes',
                {
                    event: '*',
                    schema: 'public',
                    table: "newsfeed"
                },
                (payload) => GetFeed()
            )
            .subscribe()
        return () => {
            supabase.removeChannel(channel);
        };
    }, []);

    function UpdateArticle(art){
        let arts = [];
        let found = false;
        feedarticles.forEach(tsk => {
            if(tsk.id === art.id){
                arts.push(art);
                found = true;
            } else arts.push(tsk);
        })
        if(!found) arts.push(art);
        setFeedarticles(arts);
    }

    async function GetFeed() {
        setLoadingNews(true);
        try {
            const { data, error } = await supabase
                .from('newsfeed')
                .select()
            if(error){
                setLoadingNews(false);
            } else {
                let dat = data.sort((a, b) => a.created_at > b.created_at ? -1 : 1);
                setFeedarticles(dat);
                setLoadingNews(false);
            }
        } catch (error) {
            setLoadingNews(false);
        }
    }

    return (
        <NewsContext.Provider
            value={{
                loadingNews,
                feedarticles,
                GetFeed,
                webhookURL,
                editEnabled,
                setEditEnabled
            }}>
            {children}
        </NewsContext.Provider>
    )
}

const useNewsContext = () => useContext(NewsContext);

export { NewsContext as default, NewsContextProvider, useNewsContext };