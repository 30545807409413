import {createContext, useContext, useEffect, useState} from "react";
import {supabase} from "../utils/supabase";
import {useAppContext} from "./appContext";
import secureLocalStorage from "react-secure-storage";

const SocialContext = createContext({});
let readc = secureLocalStorage.getItem("readchannel") !== null ? secureLocalStorage.getItem("readchannel") : 0;
// @ts-ignore
const SocialContextProvider = ({ children }) => {
    const [lobbyMessages, setLobbyMessages] = useState([]);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [loadingFriends, setLoadingFriends] = useState(false);
    const {profile, session, id} = useAppContext();
    const [users, setUsers] = useState([]);
    const [friends, setFriends] = useState([]);
    const [userList, setUserList] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [myRequests, setMyRequests] = useState([]);
    const [incRequests, setIncRequests] = useState([]);
    const [readChannel, setReadChannel] = useState(secureLocalStorage.getItem("readchannel"));
    const [selectedFriend, setSelectedFriend] = useState(null);

    const userStatus = {
        user: profile ? profile.username : "",
        id: id,
        online_at: new Date().toISOString(),
    }

    function setChannelRead(){
        setReadChannel(lobbyMessages.length);
        secureLocalStorage.setItem("readchannel", lobbyMessages.length);
    }

    function TotalCount(){
        return ChannelCounter() + RequestsCounter();
    }

    function ChannelCounter(){
        let am = 0;
        if(readChannel < lobbyMessages.length){
            am = lobbyMessages.length - readChannel;
        }
        return am;
    }

    function RequestsCounter(){
       let am = 0;
       for(let i = 0; i < friends.length; i++){
           if(friends[i].status !== "accepted") am++;
       }
       return am;
    }

    function friendExists(friendid, ls) {
        if(!ls || ls.length === 0) return false;
        let exists = false;
        for(let i = 0; i < ls.length; i++) {
            if(ls[i].friendid === friendid || ls[i].user_id === friendid) {
                exists = true;
                break;
            }
        }
        return exists;
    }

    function FriendOnline(friendID){
        let found = false;
        for(let i = 0; i < userList.length; i++){
            if(userList[i].user_id === friendID){
                found = true;
                break;
            }
        }
        return found;
    }

    useEffect(() => {
        if(session && profile && profile.username){
            GetLobbyMessages();
            GetFriends();
            const channel = supabase
                .channel('lobbychat')
                .on(
                    'postgres_changes',
                    {
                        event: '*',
                        schema: 'public',
                        table: "lobbychat"
                    },
                    (payload) => { GetLobbyMessages(); }
                )
                .subscribe(status => {
                    if (status === 'SUBSCRIBED') {
                        channel.track({ userStatus });
                    }
                });

            const channel2 = supabase
                .channel('friends')
                .on(
                    'postgres_changes',
                    {
                        event: '*',
                        schema: 'public',
                        table: "friends"
                    },
                    (payload) => { GetFriends(); }
                ).subscribe();

            channel.on('presence', { event: 'sync' }, () => {
                const presenceState = channel.presenceState();
                const users = Object.keys(presenceState)
                    .map((presenceId) => {
                        const presences = presenceState[presenceId];
                        return presences.map((presence) => presence.userStatus);
                    }).flat();
                setUsers(users.sort());
            });

            return () => {
                    supabase.removeChannel(channel);
                supabase.removeChannel(channel2);
            };
        } else {

        }
    }, [session, profile]);

    function UserData(uid){
        for(let i = 0; i < allUsers.length; i++){
            if(allUsers[i].user_id === uid) return allUsers[i];
        }
    }

    function UpdateMessage(msg){
        let msgs = [];
        let found = false;
        lobbyMessages.forEach(tsk => {
            if(tsk.id === msg.id){
                msgs.push(msg);
                found = true;
            } else msgs.push(tsk);
        })
        if(!found) msgs.push(msg);
        setLobbyMessages(msgs);
    }

    async function GetFriends() {
        setLoadingFriends(true);
        try {
            await supabase
                .from('friends')
                .select()
                .or(`user_id.eq.${id},friendid.eq.${id}`)
                .then(async(data) => {
                    if (data.data) {
                        setFriends(data.data);
                        let inc = [];
                        let out = [];
                        for (let i = 0; i < data.data.length; i++) {
                            if (data.data[i].status !== "accepted"){
                                if (data.data[i].user_id === id) out.push(data.data[i]);
                                else if (data.data[i].friendid === id) inc.push(data.data[i]);
                            }
                        }
                        setMyRequests(out);
                        setIncRequests(inc);
                        await supabase
                            .from('profiles')
                            .select("user_id,username")
                            .then((data2) => {
                                if (data2.data) {
                                    setAllUsers(data2.data);
                                    let ls = [];
                                    if (data2.data.length > 0) {
                                        for (let i2 = 0; i2 < data2.data.length; i2++) {
                                            let nd = data2.data[i2];
                                            if (!friendExists(nd.user_id, data.data)) {
                                                if(nd.user_id !== id) {
                                                    nd.label = nd.username;
                                                    ls.push(nd);
                                                }
                                            }
                                        }
                                    }
                                    setUserList(ls);
                                    setLoadingFriends(false);
                                } else {
                                    setAllUsers([]);
                                    setUserList([]);
                                    setMyRequests([]);
                                    setIncRequests([]);
                                    setLoadingFriends(false);
                                }
                            });
                    } else {
                        setFriends([]);
                        setAllUsers([]);
                        setUserList([]);
                        setMyRequests([]);
                        setIncRequests([]);
                        setLoadingFriends(false);
                    }
                })
        } catch (error) {
            setFriends([]);
            setAllUsers([]);
            setUserList([]);
            setMyRequests([]);
            setIncRequests([]);
            setLoadingFriends(false);
        }
    }

    async function GetLobbyMessages() {
        setLoadingMessages(true);
        try {
            const { data, error } = await supabase
                .from('lobbychat')
                .select()
            if(error){
                setLoadingMessages(false);
            } else {
                setLobbyMessages(data);
                setLoadingMessages(false);
            }
        } catch (error) {
            setLoadingMessages(false);
        }
    }

    return (
        <SocialContext.Provider
            value={{
                lobbyMessages,
                loadingMessages,
                users,
                friends,
                friendExists,
                GetFriends,
                userList,
                UserData,
                myRequests,
                incRequests,
                RequestsCounter,
                setChannelRead,
                readChannel,
                TotalCount,
                ChannelCounter,
                FriendOnline,
                selectedFriend,
                setSelectedFriend
            }}>
            {children}
        </SocialContext.Provider>
    )
}

const useSocialContext = () => useContext(SocialContext);

export { SocialContext as default, SocialContextProvider, useSocialContext };