import React, {useEffect, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import {useAdminContext} from "../contexts/adminContext";
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import {bordercolor} from "../theme";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MatchLogEntry from "../statistics/matchlogentry";
import ActiveLogEntry from "../statistics/activeLogEntry";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MatchLogList from "../statistics/matchlogList";
import {useAppContext} from "../contexts/appContext";

export function MatchOverview(props) {
    const {cardsdate} = useAppContext();
    const {reports, GetMatchReports, loadingRM, activeReport, setActiveReport, DeleteReport, activeSort,setActiveSort, SortedReports, activeType, setActiveType} = useAdminContext();
    const [hoverId, setHoverID] = useState("");

    return (
        <div className={"flex flex-col w-full h-full bg-[#131a20] overflow-hidden bg-opacity-40 justify-center items-center"}>
            <div className={"flex flex-row text-sm h-full w-full overflow-hidden"}>
                <MatchLogList admin={true} activeType={activeType} activeReport={activeReport} activeSort={activeSort} setActiveSort={setActiveSort} setActiveReport={setActiveReport} loadingRM={loadingRM} reports={reports} SortedReports={SortedReports} />
                <div className="flex flex-col h-full w-[80%] overflow-hidden container mx-auto px-2 pt-8">
                    {loadingRM ?
                        <div className="w-full lg:w-4/5 mx-auto">
                            <div className="flex flex-col gap-4 w-52">
                                <div className="skeleton h-32 w-full"></div>
                                <div className="skeleton h-4 w-28"></div>
                                <div className="skeleton h-4 w-full"></div>
                                <div className="skeleton h-4 w-full"></div>
                            </div>
                            <p>Loading Match Reports..</p>
                        </div> :
                        <div className="flex flex-col w-full h-full mx-auto overflow-hidden">
                            {!activeReport &&
                                <div className={"flex flex-col w-full h-full overflow-y-auto"}>
                                    {reports && reports.map((el, ind) =>
                                        <MatchLogEntry user={false} DeleteReport={DeleteReport} activeReport={activeReport} key={ind} ind={ind} report={el}/>)}
                                </div>}
                            {activeReport && <ActiveLogEntry user={false}  DeleteReport={DeleteReport} activeReport={activeReport}  setHoverID={setHoverID} hoverId={hoverId}/>}
                        </div>}
                </div>
            </div>
            {hoverId !== "" &&
                <div
                    className={"absolute pointer-events-none flex flex-col min-h-40 h-auto w-[400px] left-4 top-[20%] p-3 overflow-hidden self-center justify-self-center bg-[#0a0f13] rounded-2xl border " + bordercolor}>
                    <img className={"h-auto w-full rounded-2xl"} alt={"preview" + hoverId}
                         src={"/cards/" + hoverId + ".png?key=" + cardsdate}/>
                </div>}
        </div>
    )
}