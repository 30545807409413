const entrystyl = "flex flex-col w-1/4 h-full py-4 justify-start items-center bg-slate-700 rounded-2xl";

export default function PatchSample(props){
    const { notes, title } = props;

    return(
        <div className="h-[1080px] w-[1920px] bg-slate-600">
            <div className={"flex flex-row justify-center items-center text-2xl w-full h-12"}>{title}</div>
            <div className={"flex flex-row justify-between items-center text-xl w-full h-12 px-10"}>
                <p></p>
                <p className={"text-sm font-semibold"}>{notes.description}</p>
                <p className={"text-xs"}>{"Released at: " + notes.rdate}</p>
            </div>
                <div className={"flex flex-row h-full w-full px-2 gap-2"}>
                    <div className={entrystyl}>
                        <p className={"font-semibold"}>Know Issues ({notes.issues.length}):</p>
                        {notes.issues.length > 0 && notes.issues.map((issue, index) => (
                            <div key={"i" + index} className={"flex flex-wrap w-full h-auto text-sm px-2"}>
                                {"- " + issue}
                            </div>
                        ))}
                    </div>
                    <div className={entrystyl}>
                        <p className={"font-semibold"}>New Features ({notes.features.length}):</p>
                        {notes.features.length > 0 && notes.features.map((issue, index) => (
                            <div key={"f" + index}
                                 className={"flex flex-wrap w-full h-auto text-sm text-center py-2 px-2"}>
                                {"- " + issue}
                            </div>
                        ))}
                    </div>
                    <div className={entrystyl}>
                        <p className={"font-semibold"}>Improvements ({notes.improvements.length}):</p>
                        {notes.improvements.length > 0 && notes.improvements.map((issue, index) => (
                            <div key={"f" + index}
                                 className={"flex flex-wrap w-full h-auto text-sm text-center py-2 px-2"}>
                                {"- " + issue}
                            </div>
                        ))}
                    </div>
                    <div className={entrystyl}>
                        <p className={"font-semibold"}>Fixes ({notes.fixes.length}):</p>
                        {notes.fixes.length > 0 && notes.fixes.map((issue, index) => (
                            <div key={"f" + index}
                                 className={"flex flex-wrap w-full h-auto text-sm text-center py-2 px-2"}>
                                {"- " + issue}
                            </div>
                        ))}
                    </div>
                </div>
        </div>
    )
}