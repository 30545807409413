import {useStatsContext} from "../contexts/statisticsContext";
import React from "react";
import {useAppContext} from "../contexts/appContext";

export default function StatsDash(props) {
    const {profile} = useAppContext();
    const {wins, losses, totalPlayed} = useStatsContext();

    return (
        <div className={"flex flex-row h-full w-full gap-3 overflow-hidden p-4 border-[#51b78c] border-t"}>
            <div
                className={"flex flex-col h-[300px] w-[20%] border border-[#51b78c] rounded-xl justify-center items-center bg-[#183326C1]"}>
                <div>Match Statistics</div>
                <div className={"flex flex-row w-full h-14 gap-1"}>
                    <div className={"flex flex-row h-full w-1/2 gap-1 justify-center items-center"}>Matches Played
                    </div>
                    <div className={"flex flex-row h-full w-1/2 gap-1 justify-center items-center"}>{totalPlayed}</div>
                </div>
                <div className={"flex flex-row w-full h-14 gap-1"}>
                    <div className={"flex flex-row h-full w-1/2 gap-1 justify-center items-center"}>Matches Won
                    </div>
                    <div className={"flex flex-row h-full w-1/2 gap-1 justify-center items-center"}>{wins}</div>
                </div>
                <div className={"flex flex-row w-full h-14 gap-1"}>
                    <div className={"flex flex-row h-full w-1/2 gap-1 justify-center items-center"}>Matches Lost
                    </div>
                    <div className={"flex flex-row h-full w-1/2 gap-1 justify-center items-center"}>{losses}</div>
                </div>
                <div className={"flex flex-row w-full h-14 gap-1"}>
                    <div className={"flex flex-row h-full w-1/2 gap-1 justify-center items-center"}>Win Ratio
                    </div>
                    <div
                        className={"flex flex-row h-full w-1/2 gap-1 justify-center items-center"}>{wins}/{totalPlayed}</div>
                </div>
            </div>
        </div>
    )
}