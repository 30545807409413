import {bordercolor} from "../theme";
import {Card} from "./cardentries";
import React, {useEffect, useRef, useState} from "react";
import {useDbContext} from "../contexts/dbContext";
import {dbstyle} from "./dbstylings";
import {Indicator, ScrollArea, Box, Popover} from "@mantine/core";

export default function CardBox(props){
    const viewportRef = useRef(null);
    const {cardsOnly} = props;
    const {setCurrentPage, pages, currentPage, currentPageLabel, cardsLoading} = useDbContext();

    useEffect(() => {
        setCurrentPage(currentPageLabel - 1);
    }, [currentPageLabel]);

    return (
            <div id="container" className={`flex flex-col h-full w-full mb-10 lg:mb-0 overflow-hidden`}>
                <ScrollArea w={"100%"} h={"100%"} viewportRef={viewportRef}>
                    {cardsLoading ?
                        <div>Refreshing..</div>
                        :
                        <>{pages && pages.map((el2, ind) => (
                                    <div key={ind} className={(ind === currentPage ? "flex relative " : "hidden ") + " flex-wrap h-auto w-full gap-1.5 my-auto justify-center items-center py-2"}>
                                        {el2.cards.map((el2, ind2) =>
                                            <Card vis={ind=== currentPage} cardsOnly={cardsOnly} key={ind2} card={el2} type={el2.type}/>
                                        )}
                                    </div>
                        ))}</>
                    }
                </ScrollArea>
            </div>
    )
}