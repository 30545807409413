import {createTheme} from "@mui/material/styles";

export const bordercolor = "border-[#2e322e] border-opacity-60"

export const theme = createTheme({
    palette: {
        primary: {
            light: '#24D6AC',
            main: '#24D6AC',
            dark: '#24D6AC',
            contrastText: '#bcfde9',
        },
        secondary: {
            light: '#199b83',
            main: '#1c6250',
            dark: '#0c3b31',
            contrastText: '#b6ead7',
        },
    },
});